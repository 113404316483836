import {useState} from "react";

const useUser = () => {
    const defaults = {
        username: "",
        accessToken: "",
        refreshToken: "",
        isLoggedIn: false,
        isAST: false,
    };

    const [user, setUserState] = useState(defaults);

    // update the access token
    const setAccessToken = (token) => {
        setUserState((prevState) => ({
            ...prevState,
            accessToken: token,
            isLoggedIn: true,
        }));
    };

    const setUsername = (user)=>{
        setUserState((prevState) => ({
            ...prevState,
            username: user,
        }));
    };

    // update the refresh token
    const setRefreshToken = (token) => {
        setUserState((prevState) => ({
            ...prevState,
            refreshToken: token,
        }));
    };

    // log the user out
    const logoutUser = () => {
        resetUser();
        // set local storage variable to logged out - used to indicate that refresh token should not be used
        localStorage.setItem("isLoggedIn", false);
        window.location.reload(true);
    };

    // reset the user state to default values
    const resetUser = () => {
        setUserState(defaults);
    };

    const setIsAST = (ast)=>{
        setUserState((prevState) => ({
            ...prevState,
            isAST: ast,
        }));
    };

    return {user, setUsername, setAccessToken, setRefreshToken, logoutUser, setIsAST};
};

export default useUser;
