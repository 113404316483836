import * as solar from "solar-calculator";
import {
    gstime,
    propagate,
    eciToGeodetic,
    radiansToDegrees,
} from "satellite.js";


/**
 * Returns the difference in time in colon format
 * @param {String} fromTimestamp the from time as Javascript Date parsable string
 * @param {String} toTimestamp (Optional) if not provided, Current Time is used.
 * @return {Stirng} A string like 01:45:12, or if hours >= 24 250d 10h
 */
export const calcTimeUntilColon = (fromTimestamp, toTimestamp) => {
    if (!fromTimestamp || !toTimestamp) {
        return null;
    }
    const fromTime = new Date(fromTimestamp);
    const toTime = new Date(toTimestamp);
    const totalMs = fromTime.getTime() - toTime.getTime();
    const hours = Math.floor(totalMs / 3600000);
    const minutes = Math.floor((totalMs - hours * 3600000) / 60000);
    const seconds = Math.floor(
        (totalMs - hours * 3600000 - minutes * 60000) / 1000,
    );
    if (hours >= 24) {
        const days = Math.floor(hours / 24);
        return `${days}d ${Math.floor(hours - days*24)}h`;
    }
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

export const calcTimeUntilCurrentTime = (fromTimestamp, currentTime) => {
    if (!fromTimestamp) {
        return null;
    }
    const fromTime = new Date(fromTimestamp);
    const toTime = currentTime;
    const totalMs = fromTime.getTime() - toTime.getTime();
    const days = Math.floor(totalMs / (24 * 3600000));
    const hours = Math.floor((totalMs - days * 24 * 3600000) / 3600000);
    const minutes = Math.floor((totalMs - days * 24 * 3600000 - hours * 3600000) / 60000);
    const seconds = Math.floor(
        (totalMs - days * 24 * 3600000 - hours * 3600000 - minutes * 60000) / 1000,
    );
    return (days > 0 ? `${days}d ` : "")
         + (hours > 0 ? `${hours}h ` : "")
          + (days === 0 ? `${minutes}m` : "")
           + (hours === 0 ? ` ${seconds}s` : "");
};

/**
     * Converts a date time string in UTC format, into a Javascript date in UTC format.
     * @param {String} dt A UTC Timestamp string e.g 2022-10-11T12:00:00
     * @param {String} returnDate if set, a string is returned, otherwise a Javascript Date.
     * @return {Object} If returnDate is set, returns a javascript Date object else returns a string set in AU format (DD-MM-YYYY HH:mm:ss)
     */
export const toUtc = (dt, returnDate) => {
    // append Z to the end of the string if it doesn't already have it
    if (dt === null) return null;
    if (typeof dt === "string" && !dt.endsWith("Z")) {
        dt += "Z";
    }
    return returnDate
        ? new Date(dt)
        : new Date(dt).toISOString().substring(0, 19) + "Z";
};


export const useUtilities = (currentTime) => {
    /**
     * Returns the difference in time between the Aquisition of Signal and the CurrentTime.
     * @param {String} fromTimestamp the from time as Javascript Date parsable string
     * @param {String} toTimestamp (Optional) if not provided, Current Time is used.
     * @return {Stirng} A string like 1h 45m 12s
     */
    const calcTimeUntil = (fromTimestamp, toTimestamp) => {
        if (!fromTimestamp) {
            return null;
        }
        const fromTime = new Date(fromTimestamp);
        const toTime = toTimestamp ? new Date(toTimestamp) : currentTime;
        const totalMs = fromTime.getTime() - toTime.getTime();
        const days = Math.floor(totalMs / (24 * 3600000));
        const hours = Math.floor((totalMs - days * 24 * 3600000) / 3600000);
        const minutes = Math.floor((totalMs - days * 24 * 3600000 - hours * 3600000) / 60000);
        const seconds = Math.floor(
            (totalMs - days * 24 * 3600000 - hours * 3600000 - minutes * 60000) / 1000,
        );
        return (days > 0 ? `${days}d ` : "")
             + (hours > 0 ? `${hours}h ` : "")
              + (days === 0 ? `${minutes}m` : "")
               + (hours === 0 ? ` ${seconds}s` : "");
    };

    /**
     * Determines whether a groundsite location is in an eclipse or not.
     * @param {String} sat The Satellite
     * @param {String} overpass The overpass
     * @return {String} A string like 1h 45m 12s
     */
    const calcEclipse = (sat, overpass) => {
        if (!sat || !overpass || !sat.satRec) {
            return "";
        }
        const satRec = sat.satRec;

        // Sat position at AOS
        const aosDt = toUtc(overpass.aos, true);
        const aosPos = propagate(satRec, aosDt)["position"];
        const aosGmst = gstime(aosDt);
        const aosPosGd = eciToGeodetic(aosPos, aosGmst);

        const satAtAos = {
            lat: radiansToDegrees(aosPosGd.latitude),
            lon: radiansToDegrees(aosPosGd.longitude),
        };

        // Sat position at LOS
        const losDt = toUtc(overpass.los, true);
        const losPos = propagate(satRec, losDt)["position"];
        const losGmst = gstime(losDt);
        const losPosGd = eciToGeodetic(losPos, losGmst);
        const satAtLos = {
            lat: radiansToDegrees(losPosGd.latitude),
            lon: radiansToDegrees(losPosGd.longitude),
        };

        // Sun Position at AOS
        const sun = sunPosAt(aosDt);
        let sunLon = sun[1] % 360;
        if (sunLon < -180) {
            sunLon += 360;
        } else if (sunLon > 180) {
            sunLon -= 360;
        }

        let sunWEdge = sunLon - 90;
        // Wrap to -180/180
        if (sunWEdge < -180) {
            sunWEdge += 360;
        }
        let sunEEdge = sunLon + 90;
        if (sunEEdge > 180) {
            sunEEdge -= 360;
        }

        let inSunAtAos = false;
        let inSunAtLos = false;
        // NORMAL Condition
        // Sun (E > W)
        // inSun = Sat < E && Sat > W
        if (sunEEdge > sunWEdge) {
            // Satellite is in-between the eclipse boundaries
            inSunAtAos = satAtAos.lon < sunEEdge && satAtAos.lon > sunWEdge;
            inSunAtLos = satAtLos.lon < sunEEdge && satAtLos.lon > sunWEdge;
        } else {
            inSunAtAos = satAtAos.lon > sunWEdge || satAtAos.lon < sunEEdge;
            inSunAtLos = satAtLos.lon > sunWEdge || satAtLos.lon < sunEEdge;
        }
        // WRAP AROUND CONDITION
        // (W > E)
        // Sat > W || Sat < E

        if (inSunAtAos && inSunAtLos) {
            return "icon-sun";
        } else if (!inSunAtAos && !inSunAtLos) {
            return "icon-eclipse";
        } else if (inSunAtAos && !inSunAtLos) {
            return "icon-entering-eclipse";
        } else if (!inSunAtAos && inSunAtLos) {
            return "icon-exiting-eclipse";
        }
    };

    // Solar Terminator
    const sunPosAt = (dt) => {
        const now = dt ?? new Date();
        const day = new Date().setUTCHours(0, 0, 0, 0);
        const t = solar.century(now);
        const longitude = ((day - now) / 864e5) * 360 - 180;
        return [solar.declination(t), longitude - solar.equationOfTime(t) / 4];
    };

    const speak = (text) => {
        const u = new SpeechSynthesisUtterance();
        u.text = text;
        u.lang = "en-US";
        u.voice = speechSynthesis.getVoices().filter((voice) => {
            return voice.name === "Google UK English Female";
        })[0];
        u.onerror = (e) => {
            console.log(e);
        };
        speechSynthesis.speak(u);
    };

    return {
        calcTimeUntil,
        calcEclipse,
        sunPosAt,
        speak,
    };
};
