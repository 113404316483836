import React, {useState, useContext, useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GroundStationTable from "components/GroundStationTable";
import ConstellationGrid from "components/ConstellationGrid";
import warning from "lib/assets/sounds/warning.mp3";
import {AppState} from "lib/context/AppProvider";
import {GlobeState} from "lib/context/GlobeProvider";

const Overlay = () => {
    const [muteIcon, setMuteIcon] = useState("icon-mute");
    const [muteBtn, setMuteBtn] = useState("mute-btn");
    const {store} = useContext(AppState);
    const {setAudioAlert, user, satellites, selectedSatIndex} = store;
    const {globe} = useContext(GlobeState);
    const {
        trackSat,
        setTrackSat,
        hiddenSatellites,
        setHiddenSatellites,
        showConstellationGrid,
        setShowConstellationGrid,
        showOverpassTable,
        setShowOverpassTable,
        isConstellationShowing,
    } = globe;

    const [gtBtn, setGtBtn] = useState(
        showOverpassTable ? "gt-btn-1" : "gt-btn-0",
    );
    const [satBtn, setSatBtn] = useState(trackSat ? "sat-btn-1" : "sat-btn-0");
    const [constBtn, setConstBtn] = useState(
        isConstellationShowing(selectedSatIndex)
            ? "constellation-btn-enabled"
            : "constellation-btn-disabled",
    );
    const [constGridBtn, setConstGridBtn] = useState(
        showConstellationGrid
            ? "constellation-grid-btn-enabled"
            : "constellation-grid-btn-disabled",
    );

    useEffect(() => {
        if (showConstellationGrid && showOverpassTable) {
            setShowConstellationGrid(false);
        }
    }, [showConstellationGrid, showOverpassTable]);

    const handleFocusSat = () => {
        localStorage.setItem("trackSat", !trackSat);
        setTrackSat(!trackSat);
        if (trackSat) {
            setSatBtn("sat-btn-0");
        } else {
            setSatBtn("sat-btn-1");
        }
    };

    const updateConstGridBtn = () => {
        if (showConstellationGrid) {
            setConstGridBtn("constellation-grid-btn-enabled");
        } else {
            setConstGridBtn("constellation-grid-btn-disabled");
        }
    };

    useEffect(() => {
        updateConstGridBtn();
    }, [showConstellationGrid]);

    useEffect(() => {
        if (user.isLoggedIn && showConstellationGrid === null) {
            setShowConstellationGrid(user.isAST);
        }
    }, [user?.isLoggedIn]);

    useEffect(() => {
        setConstBtn(
            isConstellationShowing(selectedSatIndex)
                ? "constellation-btn-enabled"
                : "constellation-btn-disabled",
        );
    }, [hiddenSatellites]);

    const handleToggleConstellation = () => {
        if (isConstellationShowing(selectedSatIndex)) {
            setHiddenSatellites(satellites.map((s, ind) => ind));
        } else {
            setHiddenSatellites([]);
        }
    };

    const handleToggleConstellationGrid = () => {
        const newShow = !showConstellationGrid;
        setShowConstellationGrid(newShow);
        if (newShow && showOverpassTable) {
            handleShowOverpassTable();
        }
    };

    const handleShowOverpassTable = () => {
        const newShow = !showOverpassTable;
        localStorage.setItem("showTable", newShow);
        setShowOverpassTable(newShow);
        if (!newShow) {
            setGtBtn("gt-btn-0");
        } else {
            setGtBtn("gt-btn-1");
            if (showConstellationGrid) {
                handleToggleConstellationGrid();
            }
        }
    };

    const handleMute = () => {
        setAudioAlert(new Audio(warning));

        if (muteIcon === "icon-mute") {
            setAudioAlert(new Audio(warning));
            setMuteIcon("icon-un-mute");
            setMuteBtn("un-mute-btn");
        } else {
            setAudioAlert(false);
            setMuteIcon("icon-mute");
            setMuteBtn("mute-btn");
        }
    };

    const style = {
        transform: `translateY(${showOverpassTable ? "0px" : "-120%"})`,
    };
    const styleConst = {
        transform: `translateY(${showConstellationGrid ? "0px" : "-120%"})`,
    };

    return (
        user.isLoggedIn && (
            <div className="overlay">
                <Row className="panel g-0 p-0" style={style}>
                    {showOverpassTable && (
                        <Col>
                            <GroundStationTable />
                        </Col>
                    )}
                </Row>
                <Row className="panel g-0 p-0" style={styleConst}>
                    <Col className="constellation-grid-container">
                        {showConstellationGrid && <ConstellationGrid />}
                    </Col>
                </Row>
                <div
                    className={`btn-base ${constGridBtn}`}
                    onClick={handleToggleConstellationGrid}
                >
                    <img className="icon-cards" height={20} width={20} />
                </div>
                <div
                    className={`btn-base ${constBtn}`}
                    onClick={handleToggleConstellation}
                >
                    <img className="icon-const" height={20} width={20} />
                </div>
                <div
                    className={`btn-base ${satBtn}`}
                    onClick={handleFocusSat}
                >
                    <img className="icon-sat" height={20} width={20} />
                </div>
                <div
                    className={`btn-base ${gtBtn}`}
                    onClick={handleShowOverpassTable}
                >
                    <img className="icon-table" height={20} width={20} />
                </div>
                <div className={`btn-base ${muteBtn}`} onClick={handleMute}>
                    <img className={muteIcon} height={20} width={20} />
                </div>
            </div>
        )
    );
};

export default Overlay;
