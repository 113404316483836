import React, {createContext, useContext, useEffect} from "react";
import {AppState} from "lib/context/AppProvider";
import axios from "lib/config/axios";
import PropTypes from "prop-types";

export const AuthState = createContext();
export const AuthProvider = ({children}) => {
    const {store} = useContext(AppState);
    const {user, setUsername, setAccessToken, setRefreshToken, logoutUser, setIsAST}
        = store;
    const {refreshToken} = user;

    const login = async (username, password) => {
        return await axios
            .post("/login", {
                username,
                password,
            })
            .then((res) => {
                setIsAST(false);
                setUsername(username);
                setAccessToken(res.data.access_token);
                setRefreshToken(res.data.refresh_token);
                localStorage.setItem("isLoggedIn", true); // only used for refresh token
                return true;
            })
            .catch((err) => {
                throw new Error(err);
            });
    };

    const logout = async () => {
        await logoutUser();
        return true;
    };

    const refresh = async (token) => {
        if (localStorage.getItem("isLoggedIn") === "false" || token === "") {
            return;
        }

        if (user.isAST) {
            return;
        }

        const config = token
            ? {headers: {Authorization: `Bearer ${token}`}}
            : {withCredentials: true};

        try {
            const {data} = await axios.post("/refresh", {}, config);
            setAccessToken(data.access_token);
            console.debug("Refresh succeeded - New access token set");
        } catch (err) {
            console.log("Refresh token invalid");
            console.error(err);
        }
    };

    useEffect(() => {
        refresh(refreshToken).catch(() => {
            console.log("refresh_token in cookie invalid");
        });
    }, [refreshToken]);

    // Call sendRefreshRequest every 9.5 minutes
    useEffect(() => {
        const refreshTokenInterval = setInterval(() => {
            refresh(refreshToken);
        }, 570000); // 570000 milliseconds = 9.5 minutes
        return () => clearInterval(refreshTokenInterval);
    }, [refreshToken]);


    const auth = {
        /* EFFECTS */
        login,
        logout,
    };

    return <AuthState.Provider value={{auth}}>{children}</AuthState.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthProvider;
