import React, {createContext} from "react";
import useGlobeSettings from "lib/helpers/globeview/useGlobeSettings";
import PropTypes from "prop-types";

export const GlobeState = createContext("");

export const GlobeProvider = ({children}) => {
    const {
        showFuture,
        trackSat,
        hiddenSatellites,
        showConstellationGrid,
        showOverpassTable,
        showAxis,
        showSAA,
        cadModelIndex,
        telemCatIndex,
        telemCats,
        relSatTelem,
        showAllContacts,
        currentTab,
        setCadModelIndex,
        setShowFuture,
        setTrackSat,
        setHiddenSatellites,
        isConstellationShowing,
        setShowConstellationGrid,
        setShowOverpassTable,
        setShowAxis,
        setShowSAA,
        setTelemCatIndex,
        setTelemCats,
        setRelSatTelem,
        setShowAllContacts,
        setCurrentTab,
    } = useGlobeSettings();

    const globe = {
        /* STATES */
        showFuture,
        trackSat,
        hiddenSatellites,
        showConstellationGrid,
        showOverpassTable,
        showAxis,
        showSAA,
        cadModelIndex,
        telemCatIndex,
        telemCats,
        relSatTelem,
        showAllContacts,
        currentTab,

        /* SETTERS */
        setShowFuture,
        setTrackSat,
        setHiddenSatellites,
        isConstellationShowing,
        setShowConstellationGrid,
        setShowOverpassTable,
        setShowAxis,
        setCadModelIndex,
        setShowSAA,
        setTelemCatIndex,
        setTelemCats,
        setRelSatTelem,
        setShowAllContacts,
        setCurrentTab,
        /* EFFECTS */
    };

    return (
        <GlobeState.Provider value={{globe}}>{children}</GlobeState.Provider>
    );
};

GlobeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default GlobeProvider;
