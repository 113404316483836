/**
 * Analyzes the Attitude and Orbit Control System (AOCS) status based on the provided data.
 *
 * The AOCS status is determined by analyzing the various components of the AOCS, such as angular rates,
 * mode switching, and active attitude control modes. A color code is returned to indicate the overall
 * health of the AOCS: 0 = red (critical), 1 = yellow (warning), or 2 = green (normal).
 *
 * @param {object} data - An object containing key-value pairs representing different aspects of the AOCS.
 *                         Each value should be a string that can be analyzed by one of the supported analysis functions.
 *
 * @return {number} A color code indicating the overall status of the AOCS: 0 = red, 1 = yellow, or 2 = green.
 */
export function aocsStatus(data) {
    const statuses = {};
    if (!data || !data.length) {
        return {
            overall: 3,
            individual: statuses,
        };
    }

    data.forEach((point) => {
        switch (point.tlm_name) {
            case "SC_MODE":
                statuses[point.tlm_name] = {
                    status: analyzeModeSwitching(point.value),
                    value: point.value,
                };
                break;
            case "AOCS_ACMODE_ACTIVE":
                statuses[point.tlm_name] = {
                    status: analyzeACModeActive(point.value),
                    value: point.value,
                };
                break;
            case "RWA_YM0_Z_MEASSPEED":
            case "RWA_YM1_X_MEASSPEED":
            case "RWA_YP0_Z_MEASSPEED":
            case "RWA_YP1_X_MEASSPEED":
                statuses[point.tlm_name] = {
                    status: analyzeAngularRate(point.value),
                    value: point.value,
                };
                break;
        }
    });

    const worstStatus = Math.min(...Object.values(statuses).map((s) => s.status));
    return {
        overall: worstStatus,
        individual: statuses,
    };
}

/**
   * Analyzes the measured angular rate from HW.
   *
   * @param {object} value - The data object to analyze.
   * @return {number} A color code (0 = red, 1 = yellow, 2 = green)
   */
function analyzeAngularRate(value) {
    value = Math.abs(parseFloat(value));
    if (value < 4000) return 2; // Green
    else if (4000 <= value && value < 5500) return 1; // Yellow
    else return 0; // Red
}

/**
   * Analyzes the mode switching.
   *
   * @param {object} value - The data object to analyze.
   * @return {number} A color code (0 = red, 1 = yellow, 2 = green)
   */
function analyzeModeSwitching(value) {
    if (value === "THERMAL_ROLL" || value === "DEPLOYED_COAST" || value === "PLANK_NADIR") {
        return 2; // Green
    } else {
        return 1; // Yellow
    }
}

/**
   * Analyzes the current attitude control mode.
   *
   * @param {object} value - The data object to analyze.
   * @return {number} A color code (0 = red, 1 = yellow, 2 = green)
   */
function analyzeACModeActive(value) {
    if (value === "ADCS_MODE_THERMALROLL" || value === "ADCS_MODE_NADIRPOINT" || value === "ADCS_MODE_PLANKNADIR") {
        return 2; // Green
    } else {
        return 1; // Yellow
    }
}


export function epsStatus(socBattery1And2TotalPercent) {
    let status = 3; // not loaded

    if (30 < socBattery1And2TotalPercent && socBattery1And2TotalPercent < 75) {
        status = 2; // Green
    } else if (15 < socBattery1And2TotalPercent && socBattery1And2TotalPercent < 95) {
        status = 1; // Yellow
    } else {
        status = 0; // Red
    }

    return {
        overall: status,
        individual: {"SOC_BATTERY1AND2_TOTAL_PERCENT": {
            status: status,
            value: socBattery1And2TotalPercent,
        }},
    };
}


export function medicStackStatus(medicStackLocation, medicStackState) {
    const statuses = {};

    // if YM is the secondary, YP is the primary
    const isYP = (medicStackLocation === "YP" && medicStackState === "PRIMARY")
                    || (medicStackLocation === "YM" && medicStackState === "SECONDARY");
    const isYM = (medicStackLocation === "YM" && medicStackState === "PRIMARY")
                    || (medicStackLocation === "YP" && medicStackState === "SECONDARY");


    const primaryStack = isYP ? "YP" : (isYM ? "YM" : "N/A");

    const green = isYP;

    statuses["MEDIC_STACK_LOCATION"] = {
        status: !medicStackLocation ? 3 : green,
        value: medicStackLocation,
    };

    statuses["MEDIC_STACK_STATE"] = {
        status: !medicStackState ? 3 : green,
        value: medicStackState,
    };

    let status = 3; // not loaded
    if (medicStackState) {
        status = green ? 2 : 0; // Green or red
    }

    return {
        overall: status,
        individual: statuses,
        displayValue: primaryStack,
    };
}
