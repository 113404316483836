import {createContext, useState, useContext, useEffect} from "react";
import {useQueryClient} from "react-query";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom"; // replace with useNavigate()
import {Auth, Hub} from "aws-amplify";
import {AppState} from "lib/context/AppProvider";

const ASTAuthContext = createContext();
export const useASTAuthContext = () => useContext(ASTAuthContext);
class ASTUser {
    constructor(authResult) {
        this.getDecodeUserFromAuthToken(authResult);
        this.accessToken = authResult?.AccessToken;
    }

    getDecodeUserFromAuthToken = (authResult) => {
        if (authResult?.IdToken) {
            this.idToken = jwtDecode(authResult?.IdToken);
        }
    };

    getUsername = () => {
        if (this.idToken && this.idToken["email"]) {
            return this.idToken["email"];
        }
        return null;
    };

    getPermissions = () => {
        if (this.idToken && this.idToken["cognito:groups"]) {
            return this.idToken["cognito:groups"];
        }
        return [];
    };
}

function getUser() {
    return new Promise((res, rej) => {
        Auth.currentAuthenticatedUser()
            .then((authResult) => {
                if (authResult) {
                    authResult.getSession((err, session) => {
                        const idToken = session.getIdToken().getJwtToken();
                        const accessToken = session.getAccessToken().getJwtToken();
                        const user = new ASTUser({IdToken: idToken, AccessToken: accessToken});
                        res(user);
                    });
                } else {
                    console.log("AST not signed in");
                }
                // rej(null);
            })
            .catch(() => {
                console.log("AST not signed in");
                // rej("Not signed in");
            });
    });
}
/* eslint-disable no-unused-vars */
// eslint-disable-next-line react/prop-types
export const ASTContextProvider = ({children}) => {
    const {store} = useContext(AppState);
    const {user, setUsername, setAccessToken, setRefreshToken, logoutUser, setIsAST}
        = store;
    const {refreshToken} = user;

    const [userS, setUser] = useState(new ASTUser());
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const setASTUser = (newUser, showAlert=false) => {
        const missing = getMissingPermissions(newUser);
        if (missing && missing.length > 0) {
            setUser(null);
            if (showAlert) {
                alert("Missing required scopes: " + missing.join(", "));
            }
            logoutASTUser();
            return;
        }

        setUser(newUser);
        // user state stuff
        if (newUser) {
            setIsAST(true); // this must be set before the accesstoken for the useGetAllSatelliteData first load effect
            setUsername(newUser.getUsername());
            setAccessToken(newUser.accessToken);
        } else {
            logoutUser();
        }
    };

    const refreshASTToken = () => {
        console.log("Refreshing AST token.");
        getUser().then((newUser) => {
            setASTUser(newUser);
            console.log("AST refresh succeeded - new access token set.");
        });
    };

    useEffect(() => {
        Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    getUser().then((newUser) => {
                        setASTUser(newUser, true);
                        navigate("/satview");
                    });
                    break;
                case "signOut":
                    setUser(null);
                    break;
                case "signIn_failure":
                    console.log("Sign in failure", data);
                    break;
            }
        });
        getUser().then((newUser) => {
            setASTUser(newUser);
            navigate("/satview");
        });

        // Call sendRefreshRequest every 9.5 minutes
        const refreshTokenInterval = setTimeout(() => {
            refreshASTToken();
        }, 570000); // 570000 milliseconds = 9.5 minutes
        return () => clearInterval(refreshTokenInterval);
    }, []);

    const federatedLogin = () => {
        Auth.federatedSignIn({customProvider: "ASTAzureAD"});
    };

    const logoutASTUser = () => {
        queryClient.clear();
        Auth.signOut();

        /**
         * Not quite sure how it works without
         * the two lines below, but it does.
         * With the lines it seems to redirect before
         * Auth can sign out properly.
        */
        // setASTUser(null);
        // navigate("/login");
    };

    // const envType = process.env.REACT_APP_ENV_TYPE;
    // const SCOPES_FOR_ENV = {
    //     "FSW_DEV": ["MsdUser", "MsdPushToProd"],
    //     "FSW_PROD": ["MsdUser", "MsdProdWriter"],
    // };
    const SCOPES = ["MsdUser"];

    const filterApplicableRequiredScopes = (requiredScopes) => {
        return requiredScopes.filter((scope) => SCOPES.includes(scope));
    };

    const getMissingPermissions = (user) => {
        if (!user) {
            user = userS;
        }

        const missing = [];
        filterApplicableRequiredScopes(SCOPES).forEach((scope) => {
            if (!user.getPermissions().includes(scope)) {
                missing.push(scope);
            }
        });
        return missing;
    };

    const getBearerToken = async () => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken();
            const jwtToken = accessToken.getJwtToken();
            const token = `Bearer ${jwtToken}`;
            return token;
        } catch (error) {
            console.log("Error getting token: ", error);
            return null;
        }
    };

    const value = {
        user: userS,
        federatedLogin: federatedLogin,
        logoutASTUser: logoutASTUser,
        getMissingPermissions: getMissingPermissions,
        getBearerToken: getBearerToken,
        refreshASTToken: refreshASTToken,
    };

    return (
        <ASTAuthContext.Provider value={value} >
            {children}
        </ASTAuthContext.Provider>
    );
};

ASTContextProvider.defaultProps = {
    routes: () => null, // This ensures routes is always a function, even if not provided
};

export default ASTContextProvider;
