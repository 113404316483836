import axios from "lib/config/axios";

export const get = async (route, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };
    const result = await axios.get(route, config)
        .catch( (e)=>{
            throw e?.response?.data?.message ? e : new Error(e);
        });
    return result.data;
};
