import React from "react";
import GlobeProvider from "lib/context/GlobeProvider";
import AppContainer from "components/AppContainer";
import TopBar from "components/TopBar";
import Overlay from "components/Overlay";
import SpaceView from "components/SpaceView";
import LoginForm from "components/LoginScreen";
import useGetAllSatelliteData from "lib/helpers/fetchers/useGetAllSatelliteData";

import {ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const Index = () => {
    const {loading} = useGetAllSatelliteData();
    return (
        <ThemeProvider theme={darkTheme}>
            <GlobeProvider>
                <CssBaseline />
                <AppContainer>
                    <TopBar loading={loading} height={56} />
                    <Overlay />
                    <SpaceView height={"fill"} />
                    <LoginForm />
                </AppContainer>
            </GlobeProvider>
        </ThemeProvider>
    );
};

export default Index;
