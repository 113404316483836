import React, {useState, useContext} from "react";
import {AppState} from "lib/context/AppProvider";
import {AuthState} from "lib/context/AuthProvider";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import {Row} from "react-bootstrap";
import logo from "lib/assets/img/logoDouble.png";
import {useMediaQuery} from "react-responsive";
import {useASTAuthContext} from "lib/helpers/ASTAuthProvider";
import "bootstrap-icons/font/bootstrap-icons.css";

const LoginScreen = () => {
    const {store} = useContext(AppState);
    const {user} = store;

    const {auth} = useContext(AuthState);
    const {login} = auth;

    const isMobile = useMediaQuery({query: "(max-width: 760px)"});

    const [inputUsername, setInputUsername] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [attemptingLogin, setAttemptingLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {federatedLogin} = useASTAuthContext();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setAttemptingLogin(true);
        const loginAttempt = login(inputUsername, inputPassword);
        toast.promise(loginAttempt, {
            loading: "Logging in...",
            success: "Login Successful",
            error: "Invalid Login",
        })
            .finally(() => {
                setAttemptingLogin(false);
            });
    };

    const loginForm = (
        <Form onSubmit={handleSubmit}>
            <Row className="py-2 t-left px-5">
                <Form.Group controlId="formBasicUsername"
                    onChange={(e) => setInputUsername(e.target.value)}>
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="username" placeholder="Username" style={{border: "none"}}/>
                </Form.Group>
            </Row>
            <Row className="py-2 t-left px-5">
                <Form.Group controlId="formBasicPassword"
                    onChange={(e) => setInputPassword(e.target.value)}>
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                        <Form.Control autoComplete="new-password" type={showPassword ? "text" : "password"}
                            placeholder="Password" style={{border: "none"}}/>
                        <Button variant="outline-secondary"
                            style={{background: "white", border: "none"}}
                            onClick={() => setShowPassword((prev) => !prev)}>
                            {showPassword
                                ? <i className="bi bi-eye-fill" style={{color: "black"}}></i>
                                : <i className="bi bi-eye-slash-fill" style={{color: "black"}}></i>
                            }
                        </Button>
                    </InputGroup>
                </Form.Group>
            </Row>
            <Row className="py-3 place-center px-4 mx-4">
                <Button variant="primary" type="submit" disabled={attemptingLogin}>
                    { attemptingLogin
                        ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </>
                        : "BW3 Login"}
                </Button>
            </Row>
            { /* New button for AST credentials */}
            <Row className="py-1 place-center px-4 mx-4">
                <Button variant="primary" onClick={federatedLogin}>
                        Block 1 Login
                </Button>
            </Row>
        </Form>
    );

    return (
        !user.isLoggedIn
        && <div className="loginscreen">
            <div className="loginform place-center">
                <img className="login-logo" src={logo} alt="logo" height={isMobile ? 70 : 85}/>
                <p className="login-text" style={{fontSize: "30px", fontFamily: "Poppins, sans-serif"}}>SATVIEW</p>
                { loginForm }
            </div>
        </div>
    );
};

export default LoginScreen;
