import React, {useRef, useContext, useState, useEffect, useMemo} from "react";
import {AppState} from "lib/context/AppProvider";
import {GlobeState} from "lib/context/GlobeProvider";
import Globe from "react-globe.gl";
import useGlobeSettings from "lib/helpers/globeview/useGlobeSettings";
import {useUtilities} from "lib/helpers/globeview/useUtilities";
import useRenderGlobe from "lib/helpers/globeview/useRenderGlobe";
import useRenderSatellites from "lib/helpers/globeview/useRenderSatellites";
import saaPoly from "lib/assets/SAA.json";
import PropTypes from "prop-types";


const getVisibleGS = (satellites, selectedSatIndex, hiddenSatellites) => {
    if (!satellites[selectedSatIndex]?.groundstations) {
        return null;
    }

    const groundstationData = [];
    const seen = [];

    for (let i = 0; i < satellites.length; i++) {
        if (i === selectedSatIndex || !hiddenSatellites.includes(i)) {
            if (satellites[i].groundstations) {
                for (const gs of Object.values(satellites[i].groundstations)) {
                    if (!seen.includes(gs.gs_id)) {
                        groundstationData.push(gs);
                        seen.push(gs.gs_id);
                    }
                }
            }
        }
    }

    return groundstationData;
};

const GlobeView = (props) => {
    const {store} = useContext(AppState);
    const {satellites, currentTime, selectedSatIndex, setSelectedSatIndex} = store;

    const {globe} = useContext(GlobeState);
    const {showFuture, trackSat, hiddenSatellites, showAxis, cadModelIndex, showSAA} = globe;
    const globeEl = useRef();

    const {
        globeRadius,
        propStepSize,
        pathColorArray,
        ringColor,
        haloData,
        groundstations,
        setGlobeRadius,
        setHaloData,
        setGroundstations,
    } = useGlobeSettings();

    const {sunPosAt} = useUtilities(currentTime);

    const [groundstationData, setGroundStationData] = useState(null);
    useEffect(() => {
        setGroundStationData(getVisibleGS(satellites, selectedSatIndex, hiddenSatellites));
    }, [satellites[selectedSatIndex]?.groundstations, selectedSatIndex, hiddenSatellites]);

    const {
        onGlobeReady,
        globeMaterial,
        globeImage,
        bumpImage,
        backgroundImage,
        solarMaterial,
        sun,
    } = useRenderGlobe(
        groundstationData,
        globeEl,
        setGlobeRadius,
        showAxis,
        setGroundstations,
    );

    const {satData, satObject} = useRenderSatellites(
        globeEl,
        showFuture,
        propStepSize,
        globeRadius,
        setHaloData,
        trackSat,
        // showConstellationGrid ? [] : hiddenSatellites, // show all sats if grid is on as well
        hiddenSatellites,
        sunPosAt,
        sun,
        showAxis,
        cadModelIndex,
        satellites,
    );

    // Polygons
    const [countries, setCountries] = useState({features: []});
    useEffect(() => {
        setCountries(saaPoly);
    }, []);

    const labelsData = useMemo(() => groundstations, [groundstations]);

    const htmlElementsData = useMemo(() => {
        const labels = [...satData.map((s) => {
            const sat = satellites[s.index];
            return {
                name: sat.sat_name,
                index: s.index,
                lat: s.pos.lat,
                lng: s.pos.lng,
                alt: s.pos.alt,
                color: "lightgrey",
            };
        })];
        return labels;
    }, [groundstations, satData]);

    const htmlElementAccessor = (data) => {
        const contents = data.name;

        const shadow = "0px 0px 10px black";
        const numShadow = 10; // how many to stack upon each other
        const stackedShadow = [...Array(numShadow)].map(() => shadow).join(",");

        const el = document.createElement("div");
        el.innerHTML = contents;
        el.style.color = data.color;
        el.style.marginTop = "20px";
        el.style.fontSize = "14px";
        el.style.textShadow = stackedShadow;
        el.style.zIndex = 2;

        el.style["pointer-events"] = "auto";
        el.style.cursor = "pointer";
        el.onclick = () => setSelectedSatIndex(data.index);
        return el;
    };

    const objectsData = useMemo(
        () => satData.map((s) => {
            return {lat: s.pos.lat, lng: s.pos.lng, alt: s.pos.alt, index: s.index};
        }),
        [satData],
    );
    const pathsData = useMemo(
        () => satData,
        [satData],
    );
    const ringsData = useMemo(() => haloData, [haloData]);
    const tilesData = useMemo(() => [{pos: sunPosAt()}], [sunPosAt()]);
    const polygonsData = useMemo(
        () => (showSAA ? countries.features : []),
        [countries.features, showSAA],
    );

    const handleContextLost = (event) => {
        console.log("Handling Web GL context lost");
        // refresh the page
        window.location.reload(true);
    };

    const onObjectClick = (obj, event, {lat, lng, altitude}) => {
        setSelectedSatIndex(obj.index);
    };

    useEffect(() => {
        const globeInstance = globeEl.current;
        const canvas = globeInstance.renderer().domElement;
        canvas.addEventListener("webglcontextlost", handleContextLost, false);
        return () => {
            canvas.removeEventListener(
                "webglcontextlost",
                handleContextLost,
                false,
            );
        };
    }, [handleContextLost]);

    return (
        <Globe
            // GlobeTexture
            ref={globeEl}
            onGlobeReady={onGlobeReady}
            globeMaterial={globeMaterial}
            height={props.size.height}
            width={props.size.width}
            animateIn={false}
            globeImageUrl={globeImage}
            bumpImageUrl={bumpImage}
            backgroundImageUrl={backgroundImage}
            // New labels???
            htmlElementsData={htmlElementsData}
            htmlAltitude={(d) => d.alt ?? 0}
            htmlElement={htmlElementAccessor}
            // Satellite
            objectsData={objectsData}
            objectLat="lat"
            objectLng="lng"
            objectAltitude="alt"
            objectThreeObject={satObject} // TODO: different objects per satellite
            onObjectClick={onObjectClick}
            // objectLabel={(sat) => satellites[sat.index].sat_name}
            // Satellite Orbit
            pathsData={pathsData}
            pathPoints="path"
            pathColor={(sat) => pathColorArray[sat.index === selectedSatIndex ? 0 : 1] }
            pathDashLength={1}
            pathStroke={2}
            pathDashGap={1}
            pathDashAnimateTime={0}
            pathTransitionDuration={0}
            pathPointAlt={(arr) => arr[2]}
            // Select Rings
            ringsData={ringsData}
            ringColor={() => ringColor}
            ringAltitude={"alt"}
            ringMaxRadius={14}
            ringPropagationSpeed={2}
            ringRepeatPeriod={2000}
            // Groundsite Labels
            labelsData={labelsData}
            labelText={(d) => d.name}
            labelColor={(d) => d.dotColor}
            labelDotRadius={(d) => d.dotRadius}
            labelDotOrientation={(d) => d.dotOrientation}
            labelAltitude={(d) => d.alt ?? 0.001}// {0.001}
            labelLat={(d) => d.lat}
            labelLng={(d) => d.lng}
            labelSize={(d) => d.size}
            labelRotation={(d) => d.labelRotation ?? 0}
            labelsTransitionDuration={0}
            // DayNight Terminator
            tilesData={tilesData}
            tileLat={(d) => -1 * d.pos[0]}
            tileLng={(d) => d.pos[1] - 180} // The center of the tile should be on the opposite side to the sun
            tileAltitude={0.005}
            tileWidth={180}
            tileHeight={180}
            tileUseGlobeProjection={false}
            tileMaterial={() => solarMaterial}
            tilesTransitionDuration={0}
            // Country Polygons
            polygonsData={polygonsData}
            polygonAltitude={0.075}
            polygonCapCurvatureResolution={5}
            polygonCapColor={() => "rgba(200, 0, 0, 0)"}
            polygonSideColor={() => "rgba(200, 0, 0, 0.3)"}
            polygonLabel={() => "South Atlantic Anomaly (SAA)"}
            polygonsTransitionDuration={0}
        />
    );
};

GlobeView.propTypes = {
    size: PropTypes.object.isRequired,
};

export default GlobeView;
