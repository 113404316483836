const toastConfig = {
    position: "bottom-center",
    reverseOrder: true,
    gutter: 12,
    containerStyle: {
        "inset": "8rem",
        "zIndex": 50000,
        "minWidth": "150px",
    },
    toastOptions: {
        style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
        },
        success: {
            iconTheme: {
                primary: "#444444",
                secondary: "#ffc45d",
            },
        },
        loading: {
            iconTheme: {
                primary: "#444444",
                secondary: "#ffc45d",
            },
        },
        error: {
            iconTheme: {
                primary: "#444444",
                secondary: "#ffc45d",
            },
        },
    },
};

export default toastConfig;
