import React, {useState, useContext, useEffect} from "react";
import {AppState} from "lib/context/AppProvider";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "lib/assets/img/logo.png";
import mobileLogo from "lib/assets/img/mobileLogo.png";
import Button from "react-bootstrap/Button";
import SatMenu from "components/SatMenu";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import {useMediaQuery} from "react-responsive";

const TopBar = (props) => {
    const style = {
        height: props.height,
        minHeight: props.height,
    };
    const isMobile = useMediaQuery({query: "(max-width: 1045px)"});
    const {store} = useContext(AppState);
    const {
        satellites, selectedSatIndex, setSelectedSatIndex, currentTime, user,
        showSatMenu, setShowSatMenu,
    } = store;

    const [imageIndex, setImageIndex] = useState(0);
    const images = ["gs0.png", "gs1.png", "gs2.png", "gs3.png"];

    useEffect(() => {
        let timer;
        if (props.loading) {
            timer = setInterval(() => {
                setImageIndex((prevIndex) => (prevIndex + 1) % 4); // Cycle through 0, 1, 2, 3
            }, 500);
        }
        return () => {
            if (timer) {
                setImageIndex(3);
                clearInterval(timer);
            }
        };
    }, [props.loading]);

    const handleShow = () => {
        setShowSatMenu(!showSatMenu);
    };
    const handleClose = () => {
        setShowSatMenu(false);
    };

    const handleRefresh = () => {
        window.location.reload(true); // True will reload from server, not cache.
    };

    return (
        user.isLoggedIn
        && <>
            <Row className="topbar g-0 p-0 z-10" style={style}>
                <Col xs={isMobile ? 3 : 4} className="pt-3">
                    <img
                        className= {isMobile ? "mobile-logo" : "logo"}
                        src={isMobile ? mobileLogo : logo}
                        onClick={handleRefresh}
                        alt="logo"
                        height={24}
                        style={{cursor: "pointer"}}
                    />
                </Col>
                <Col xs={isMobile ? 6 : 4} className="pt-2">
                    <Form.Select
                        className="satelliteSelect"
                        onChange={(e) => {
                            setSelectedSatIndex(e.target.selectedIndex);
                        }}
                        value={
                            satellites[selectedSatIndex]?.sat_name
                        } // might cause an error in console but works fine https://github.com/Shopify/polaris/issues/98
                    >
                        {satellites.map((row) => {
                            return (
                                <option id={row.sat_id} key={row.sat_name}>
                                    {row.sat_name}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Col>
                <Col xs={isMobile ? 3 : 4} className="pt-2">
                    <Button
                        className="float-end satellite-menu"
                        variant="secondary"
                        onClick={handleShow}
                    >
                        {!isMobile && (
                            <span className="clock">
                                {currentTime.toISOString().substring(0, 19)
                                    + "Z"}{" "}
                            </span>
                        )}
                        <img src={images[imageIndex]} height={25} width={25} />
                    </Button>
                </Col>
            </Row>
            <SatMenu
                satellite={satellites[selectedSatIndex]}
                visible={showSatMenu}
                handleClose={handleClose}
            />
        </>
    );
};

TopBar.propTypes = {
    height: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default TopBar;
