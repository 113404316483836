const isLocalhost = Boolean(
    window.location.hostname === "localhost"
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === "[::1]"
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

const ssoRedirectURL
    = isLocalhost
        ? "http://localhost:3000/"
        : (window.location.hostname === "satview.saberastro.com"
            ? "https://satview.saberastro.com/satview/"
            : `https://${window.location.hostname}/`);

export const awsConfig = {
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'us-east-1:edcf31da-8f3e-4b91-b748-fdde10163ccf',
        // REQUIRED - Amazon Cognito Region
        region: "us-east-1",
        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'us-east-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: "us-east-1_pIu9rBdsz",
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: "3vr216d6mfirv99m3uep9v150q",
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // mandatorySignIn: false,
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        // signUpVerificationMethod: 'code', // 'code' | 'link'
        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        // // OPTIONAL - Cookie path
        //     path: '/',
        // // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        //     sameSite: "strict" | "lax",
        // // OPTIONAL - Cookie secure flag
        // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true
        // },
        // OPTIONAL - customized storage object
        // storage: MyStorage,
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: "USER_SRP_AUTH",
        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },
        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: "astauth.auth.us-east-1.amazoncognito.com",
            scope: ["openid"],
            // redirectSignIn: 'https://lcs.astswg.com/contact-list',
            // redirectSignOut: 'https://lcs.astswg.com/sign-in',
            redirectSignIn: ssoRedirectURL,
            redirectSignOut: ssoRedirectURL,
            responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
    storage: window.sessionStorage,
};
