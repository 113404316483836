/**
 *
 * BLUEWALKER-3
 *
 */
const bw3TelemCategories = {
    "SOH": [
        "SBAND_GWD_COUNTER",
        "UHF_GS_WDT_COUNTER",
        "SOC_BATTERY1AND2_TOTAL_PERCENT",
        "PCDU_BATT_BFCP_XP",
        "ADCS_MODE",
        "SC_MODE",
        "AOCS_ACSUBMODE_ACTIVE",
        "GPS_FIX",
        // "AOCS_H_B_TOTAL",
        "AOCS_WBI_B_SV_ACTIVE_0_DEG",
        "AOCS_WBI_B_SV_ACTIVE_1_DEG",
        "AOCS_WBI_B_SV_ACTIVE_2_DEG",
        "AOCS_BETA_ANGLE_DEG",
        "AOCS_ORBIT_SEED_TIMER",
        "RWA_YM0_Z_MEASSPEED",
        "RWA_YM1_X_MEASSPEED",
        "RWA_YP0_Z_MEASSPEED",
        "RWA_YP1_X_MEASSPEED",
        "ROLL_DEG",
        "PITCH_DEG",
        "YAW_DEG",
    ],
    "COMM": [
        "SBAND_GWD_COUNTER",
        "BOOT_COUNT",
        "UHF_GS_WDT_COUNTER",
        "LVC_WDT_TIME_LEFT",
        "UHF_GS_WDT_TIME_LEFT_IN_SEC",
        "TIME_SINCE_LAST_COMM_IN_SEC",
        // "QVA_YP_STATUS_CURRENT_STATE",
        "QVA_YM_STATUS_CURRENT_STATE",
    ],
    "AOCS": [
        "ADCS_MODE",
        "SC_MODE",
        "AOCS_ACSUBMODE_ACTIVE",
        // "AOCS_H_B_TOTAL",
        // "AOCS_TH_B_ERROR_0_DEG",
        // "AOCS_TH_B_ERROR_1_DEG",
        // "AOCS_TH_B_ERROR_2_DEG",
        "AOCS_TH_B_ERROR_0",
        "AOCS_TH_B_ERROR_1",
        "AOCS_TH_B_ERROR_2",
        // "AOCS_WBI_B_SV_ACTIVE_0_DEG",
        // "AOCS_WBI_B_SV_ACTIVE_1_DEG",
        // "AOCS_WBI_B_SV_ACTIVE_2_DEG",
        "AOCS_WBI_B_SV_ACTIVE_0",
        "AOCS_WBI_B_SV_ACTIVE_1",
        "AOCS_WBI_B_SV_ACTIVE_2",
        "RWA_YM0_Z_MEASSPEED",
        "RWA_YM1_X_MEASSPEED",
        "RWA_YP0_Z_MEASSPEED",
        "RWA_YP1_X_MEASSPEED",
        "ROLL_DEG",
        "PITCH_DEG",
        "YAW_DEG",
        "GPS_FIX",
        "GPS_x0",
        "GPS_x1",
        "GPS_x2",
        "GPS_v0",
        "GPS_v1",
        "GPS_v2",
        "GPS_TIME_SEC",
        "AOCS_ECLIPSEFLAG",
        "AOCS_BETA_ANGLE_DEG",
        "ST_MODE_XM",
        "ST_MODE_XP",
    ],
    "THRML": [
        "APC_BOARD_TEMP",
        "FC_BOARD_TEMP",
        "GPS_BOARD_TEMP",
        "AGG1_BOARD_TEMP",
        "AGG2_BOARD_TEMP",
        "PCDU_BATT_ADC_THERM1",
        "PCDU_BATT_ADC_THERM2",
        "PCDU_BATT_ADC_THERM3",
        "PCDU_BATT_ADC_THERM4",
        "PCDU_MICRON_ADC_THERM1",
        "PCDU_MICRON_ADC_THERM2",
        "PCDU_MPPT_SWITCH_ADC_THERM1",
        "PCDU_MPPT_SWITCH_ADC_THERM2",
    ],
    "EPS": [
        "SOC_BATTERY1AND2_TOTAL",
        "SOC_BATTERY1AND2_TOTAL_PERCENT",
        "SOC_BATTERY1_VOLTAGE_MEDIAN",
        "SOC_BATTERY2_VOLTAGE_MEDIAN",
        "PCDU_BATT_BFCP_XP",
        "PCDU_BATT_BFCP_XM",
    ],
    "MICRON PM": ["*MICRON_[0-9]{1,3}_POWER_MODE"],
    "MICRON SoC": ["*MICRON_[0-9]{1,3}_SOC"],
    "OPEN TICKETS": [],
    "FDIR SUMMARY": [],
};

/**
 *
 * BLOCK 1 (SM001-005)
 *
 */
const block1TelemCategories = {
    "SOH": [
        "SBAND_GWD_COUNTER",
        "UHF_GS_WDT_COUNTER",
        "SOC_BATTERY1AND2_TOTAL_PERCENT",
        "PCDU_BATT_BFCP_XP",
        "SC_MODE",
        "AOCS_ACMODE_ACTIVE",
        "AOCS_ACSUBMODE_ACTIVE",
        "GPS_FIX",
        // "AOCS_H_B_TOTAL",
        "AOCS_WBI_B_SV_ACTIVE_0_DEG",
        "AOCS_WBI_B_SV_ACTIVE_1_DEG",
        "AOCS_WBI_B_SV_ACTIVE_2_DEG",
        "AOCS_BETA_ANGLE_DEG",
        "AOCS_ORBIT_SEED_TIMER",
        "RWA_YM0_Z_MEASSPEED",
        "RWA_YM1_X_MEASSPEED",
        "RWA_YP0_Z_MEASSPEED",
        "RWA_YP1_X_MEASSPEED",

        "MEDIC_STACK_LOCATION",
        "MEDIC_STACK_STATE",

        "ROLL_DEG",
        "PITCH_DEG",
        "YAW_DEG",
    ],
    "COMM": [
        "SBAND_GWD_COUNTER",
        "BOOT_COUNT",
        "UHF_GS_WDT_COUNTER",
        "LVC_WDT_TIME_LEFT",
        "UHF_GS_WDT_TIME_LEFT_IN_SEC",
        "TIME_SINCE_LAST_COMM_IN_SEC",
        // "QVA_YP_STATUS_CURRENT_STATE",
        "QVA_YM_STATUS_CURRENT_STATE",
    ],
    "AOCS": [
        "SC_MODE",
        "AOCS_ACMODE_ACTIVE",
        "AOCS_ACSUBMODE_ACTIVE",
        // "AOCS_H_B_TOTAL",
        // "AOCS_TH_B_ERROR_0_DEG",
        // "AOCS_TH_B_ERROR_1_DEG",
        // "AOCS_TH_B_ERROR_2_DEG",
        "AOCS_TH_B_ERROR_0",
        "AOCS_TH_B_ERROR_1",
        "AOCS_TH_B_ERROR_2",
        // "AOCS_WBI_B_SV_ACTIVE_0_DEG",
        // "AOCS_WBI_B_SV_ACTIVE_1_DEG",
        // "AOCS_WBI_B_SV_ACTIVE_2_DEG",
        "AOCS_WBI_B_SV_ACTIVE_0",
        "AOCS_WBI_B_SV_ACTIVE_1",
        "AOCS_WBI_B_SV_ACTIVE_2",
        "RWA_YM0_Z_MEASSPEED",
        "RWA_YM1_X_MEASSPEED",
        "RWA_YP0_Z_MEASSPEED",
        "RWA_YP1_X_MEASSPEED",
        "ROLL_DEG",
        "PITCH_DEG",
        "YAW_DEG",
        "GPS_FIX",
        "GPS_x0",
        "GPS_x1",
        "GPS_x2",
        "GPS_v0",
        "GPS_v1",
        "GPS_v2",
        "GPS_TIME_SEC",
        "AOCS_ECLIPSEFLAG",
        "AOCS_BETA_ANGLE_DEG",
        "ST_MODE_XM",
        "ST_MODE_XP",
    ],
    "THRML": [
        "APC_BOARD_TEMP",
        "FC_BOARD_TEMP",
        "GPS_BOARD_TEMP",
        "AGG1_BOARD_TEMP",
        "AGG2_BOARD_TEMP",
        "PCDU_BATT_ADC_THERM1",
        "PCDU_BATT_ADC_THERM2",
        "PCDU_BATT_ADC_THERM3",
        "PCDU_BATT_ADC_THERM4",
        "PCDU_MICRON_ADC_THERM1",
        "PCDU_MICRON_ADC_THERM2",
        "PCDU_MPPT_SWITCH_ADC_THERM1",
        "PCDU_MPPT_SWITCH_ADC_THERM2",
    ],
    "EPS": [
        "SOC_BATTERY1AND2_TOTAL",
        "SOC_BATTERY1AND2_TOTAL_PERCENT",
        "SOC_BATTERY1_VOLTAGE_MEDIAN",
        "SOC_BATTERY2_VOLTAGE_MEDIAN",
        "PCDU_BATT_BFCP_XP",
        "PCDU_BATT_BFCP_XM",
    ],
    "MICRON PM": ["*MICRON_[0-9]{1,3}_POWER_MODE"],
    "MICRON SoC": ["*MICRON_[0-9]{1,3}_SOC"],
    "OPEN TICKETS": [],
    "FDIR SUMMARY": [],
};

// TODO: why are we using norad ID, this means if the IDs change Satview breaks
export const satConfig = {
    53807: {
        sat_name: "BlueWalker-3",
        sat_id: "sbw3",
        telemetryCategories: bw3TelemCategories,
    },
    61047: {
        sat_name: "SM001",
        sat_id: "SM001",
        telemetryCategories: block1TelemCategories,
    },
    61048: {
        sat_name: "SM002",
        sat_id: "SM002",
        telemetryCategories: block1TelemCategories,
    },
    61045: {
        sat_name: "SM003",
        sat_id: "SM003",
        telemetryCategories: block1TelemCategories,
    },
    61049: {
        sat_name: "SM004",
        sat_id: "SM004",
        telemetryCategories: block1TelemCategories,
    },
    61046: {
        sat_name: "SM005",
        sat_id: "SM005",
        telemetryCategories: block1TelemCategories,
    },
};
