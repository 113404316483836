import {Route, Routes, Navigate} from "react-router-dom";
import Index from "pages/Index";
import Load from "lib/helpers/Load";

const App = () => {
    return (
        <Routes>
            <Route path='/' element={ <Load page={<Index/>}/> }/>
            <Route path='/*' element={ <Navigate to="/" replace={true} /> }/> {/* catch all, including 404 routes */}
        </Routes>
    );
};

export default App;
