import React from "react";

import Row from "react-bootstrap/Row";
import Alerts from "components/Alerts";
import GlobeView from "components/GlobeView";
import {SizeMe} from "react-sizeme";
import PropTypes from "prop-types";

const SpaceView = (props) => {
    const style = {
        height: props.height === "fill" ? "" : props.height,
        minHeight: props.height === "fill" ? "" : props.height,
        flexGrow: props.height === "fill" ? 1 : "",
        zIndex: 1, // so labels don't cover the constellation card popup
    };

    return (
        <Row className="spaceview g-0 p-0" style={style}>
            <Alerts />
            <SizeMe monitorHeight>{({size}) => (
                <GlobeView size={size} />
            )}</SizeMe>
        </Row>
    );
};

SpaceView.propTypes = {
    height: PropTypes.node.isRequired,
};

export default SpaceView;
