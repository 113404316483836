import {useState} from "react";

const useSatellites = () => {
    const [satellites, setSatellites] = useState([]);
    const [selectedSatIndex, setSelectedSatIndex] = useState(null);
    const [nextPass, setNextPass] = useState({});
    const [previousPass, setPreviousPass] = useState({});
    const [currentPass, setCurrentPass] = useState({});
    const [telemQueries, setTelemQueries] = useState([]);
    const [telemUpdated, setTelemUpdated] = useState(null);
    const [audioAlert, setAudioAlert] = useState(false);
    const [telemType, setTelemType] = useState(localStorage.getItem("TelemType")
                                                ?? "Latest");
    const configureTelem = (satNo, queries)=>{
        setTelemQueries(queries.map((q)=>q.replace("<SATNO>", satNo)));
    };

    return {
        satellites, setSatellites,
        nextPass, setNextPass,
        currentPass, setCurrentPass,
        telemQueries, configureTelem,
        selectedSatIndex, setSelectedSatIndex,
        telemUpdated, setTelemUpdated,
        previousPass, setPreviousPass,
        audioAlert, setAudioAlert,
        telemType, setTelemType,
    };
};

export default useSatellites;
