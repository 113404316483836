import React, {useContext, useEffect, useMemo, useState} from "react";
// import Offcanvas from "react-bootstrap/Offcanvas";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import toast from "react-hot-toast";
import {GlobeState} from "lib/context/GlobeProvider";
import {AppState} from "lib/context/AppProvider";
import {sortBy} from "lodash";
import PropTypes from "prop-types";
import {useUtilities, toUtc} from "lib/helpers/globeview/useUtilities";
import {satConfig} from "lib/config/sats.js";

import {useASTAuthContext} from "lib/helpers/ASTAuthProvider";
import {useMediaQuery} from "react-responsive";
import {useInView} from "react-intersection-observer";

import {
    // STATUS_COLOR_GREEN,
    STATUS_COLOR_YELLOW,
    STATUS_COLOR_RED,
    // STATUS_COLOR_NOT_LOADED,
    // STATUS_COLOR_WHITE,
} from "./ConstellationGrid";

const formatValue = (value) => {
    if (isNaN(value) || !value) {
        return value+"";
    }
    const decimals = Math.abs(value) < 1 ? 5 : 2;
    return parseFloat(value.toFixed(decimals));
};

const getWindowDimensions = () => {
    return {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
    };
};

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};

const SatMenu = React.memo(({satellite, visible, handleClose}) => {
    const {ref, inView} = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });
    const {store} = useContext(AppState);
    const {logoutASTUser} = useASTAuthContext();
    const {
        currentTime,
        telemType,
        setTelemType,
    } = store;
    const {globe} = useContext(GlobeState);
    const {
        cadModelIndex,
        showAxis,
        showSAA,
        showFuture,
        telemCatIndex,
        telemCats,
        relSatTelem,
        currentTab,
        setCadModelIndex,
        setShowAxis,
        setShowFuture,
        setShowSAA,
        setTelemCatIndex,
        setTelemCats,
        setRelSatTelem,
        setCurrentTab,
    } = globe;
    const {calcTimeUntil} = useUtilities(currentTime);
    const tle = satellite?.tle;
    const [tableColumns, setTableColumns] = useState(3);

    const {innerWidth} = useWindowDimensions();

    const isMobile = useMediaQuery({query: "(max-width: 1045px)"});
    const TRUNCATE_MAX_NAME = innerWidth >= 500 ? 30 : (isMobile ? 11 : 20);
    const TRUNCATE_MAX_VALUE = 11;

    if (store.user.isAST && telemType === "Latest") {
        setTelemType("Automatic");
    } else if (!store.user.isAST && telemType === "Automatic") {
        setTelemType("Latest");
    }

    const mostRecent = (telem) => {
        if (telem.length === 0) {
            return "N/A";
        }
        const sorted = sortBy(telem, (t) => {
            return new Date(t.observed_at);
        });
        return calcTimeUntil(
            currentTime,
            toUtc(sorted[sorted.length - 1].observed_at),
        );
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        toast("Copied to clipboard");
    };

    const toolTipHideDelay = 2000;
    const renderTooltip = (props) => {
        let message = "";
        if (props.popper.state) {
            message = props.popper.state.options.msg;
        }

        return (
            <Tooltip id="button-tooltip" {...props} style={{position: "absolute", top: "0px", right: "25%"}}>
                <div style={{userSelect: "text"}}>{message}</div>
                <Button variant="light" size="sm" onClick={() => handleCopy(message)} style={{marginTop: "5px"}}>
                    Copy
                </Button>
            </Tooltip>
        );
    };

    const truncateStr = (str, max) => {
        if (!str) {
            return str;
        }

        if (str.length > max) {
            return str.slice(0, max) + "...";
        } else {
            return str;
        }
    };

    const truncate = (str, isName=false) => {
        return truncateStr(str, isName ? TRUNCATE_MAX_NAME : TRUNCATE_MAX_VALUE);
    };

    const handleLogout = () => {
        const {user} = store;

        if (user.isAST) {
            logoutASTUser();
        } else {
            store.logoutUser();
        }
    };

    const memoizedTelemCats = useMemo(() => {
        return satellite && satConfig && satConfig[satellite.norad_id]
            ? Object.keys(satConfig[satellite.norad_id].telemetryCategories)
            : null;
    }, [satellite?.norad_id]);

    const memoizedRelSatTelem = useMemo(() => {
        if (
            !satellite
            // || !satellite.telemetry
            // || !satellite.telemetry.length > 0
            || !telemCats
        ) {
            return null;
        }

        const selectedCategory = telemCats[telemCatIndex];

        const relSatTelem = [];
        if (satellite.telemetry && satellite.telemetry.length > 0) {
            satConfig[satellite.norad_id].telemetryCategories[
                selectedCategory
            ].forEach((rx) => {
                // if the first character is a *, remove it and use .some instead of .find to get all results
                if (rx.charAt(0) === "*") {
                    const regex = new RegExp(rx.slice(1));
                    satellite.telemetry.forEach((telem) => {
                        if (regex.test(telem.tlm_name)) {
                            relSatTelem.push(telem);
                        }
                    });
                } else {
                    // const matchingTelem = satellite.telemetry.find((telem) =>
                    //     new RegExp(rx).test(telem.tlm_name),
                    // );

                    // the above causes SOC_BATTERY1AND2_TOTAL_PERCENT to match SOC_BATTERY1AND2_TOTAL
                    // I don't believe we really need subsets
                    const matchingTelem = satellite.telemetry.find((telem) => rx === telem.tlm_name);
                    if (matchingTelem) {
                        relSatTelem.push(matchingTelem);
                    }
                }
            });
        }

        if (selectedCategory === "OPEN TICKETS" && satellite?.open_tickets) {
            satellite?.open_tickets.forEach((ticket, i) => {
                const statusAbbv = ticket.status ? ticket.status[0] : "N/A";
                relSatTelem.push({
                    key: `TICKET_${i}`,
                    component: "TICKET",
                    tlm_name: ticket.summary,
                    tlm_id: ticket.description,
                    value: [statusAbbv, ticket.priority],
                    value_tooltips: [ticket.status, ticket.priority],
                    observed_at: ticket.updated_at,
                });
            });
        }

        if (selectedCategory === "FDIR SUMMARY" && satellite?.fdir) {
            // AST changed from 4 types for each subsystem to just yellow and red total counter
            // const types = ["NOT_MANAGED_OR_INACTIVE", "OK", "YELLOW", "RED"];
            const types = ["YELLOW", "RED"];
            // const counters = ["AOCS", "CDH", "EPS", "PRO", "QV", "THR", "TTC", "TOTAL"];
            const counters = ["TOTAL"];
            // const colors = [STATUS_COLOR_NOT_LOADED, STATUS_COLOR_GREEN, STATUS_COLOR_YELLOW, STATUS_COLOR_RED];
            const colors = [STATUS_COLOR_YELLOW, STATUS_COLOR_RED];

            const fdir = satellite.fdir;

            for (const counter of counters) {
                relSatTelem.push({
                    key: counter,
                    component: fdir.component,
                    tlm_name: counter,
                    tlm_id: `${fdir.tlm_id} (${fdir.packet_name})`,
                    value: types.map((t) => fdir[counter][t]),
                    value_tooltips: types,
                    value_colors: colors,
                    observed_at: fdir.observed_at,
                });
            }
        }

        if (relSatTelem.length === 0 && (!satellite.telemetry || satellite.telemetry.length <= 0)) {
            return null;
        }

        return relSatTelem;
    }, [telemCatIndex, satellite?.telemetry, satellite?.open_tickets, telemCats]);

    useEffect(() => {
        setTelemCats(memoizedTelemCats);
    }, [memoizedTelemCats]);

    useEffect(() => {
        setRelSatTelem(memoizedRelSatTelem);
    }, [memoizedRelSatTelem]);

    useEffect(() => {
        if (telemCatIndex && (
            telemCats[telemCatIndex] === "FDIR SUMMARY"
        )) {
            setTableColumns(4); // 6);
        } else if (telemCatIndex && (
            telemCats[telemCatIndex] === "MICRON SoC" || telemCats[telemCatIndex] === "OPEN TICKETS"
        )) {
            setTableColumns(4);
        } else {
            setTableColumns(3);
        }
    }, [telemCatIndex, telemCats]);

    const renderTelemValues = (row) => {
        if (tableColumns >= 4) {
            return (
                <>
                    {[...Array(tableColumns-2).keys()].map((i) => (
                        <OverlayTrigger
                            key={`COLUMN-${i}`}
                            placement="top"
                            delay={{show: 250, hide: toolTipHideDelay}}
                            overlay={renderTooltip}
                            popperConfig={{msg: row.value_tooltips ? row.value_tooltips[i] : row.value[i]}}
                        >
                            <td style={row.value_colors ? {color: row.value_colors[i]} : {}}>
                                {truncate(formatValue(row.value[i]))}
                            </td>
                        </OverlayTrigger>
                    ))}
                </>
            );
        } else if (tableColumns === 3) {
            return (
                <OverlayTrigger
                    placement="top"
                    delay={{show: 250, hide: toolTipHideDelay}}
                    overlay={renderTooltip}
                    popperConfig={{msg: row.value}}
                >
                    <td>{truncate(formatValue(row.value))}</td>
                </OverlayTrigger>
            );
        }
    };

    const renderTelemTableBody = (telem) => {
        return telem.map((row) => {
            // augmentations go here ------------
            let tlmName;
            if (row.tlm_name === "AOCS_ORBIT_SEED_TIMER") {
                tlmName = "SEED_TIME_SINCE_LAST_INGEST";
                row.value = Math.round(row.value);
            } else {
                tlmName = row.tlm_name;
            }
            // end augmentations ----------------
            return (
                <tr
                    key={row.key ?? row.tlm_id}
                    className="table-row"
                >
                    <OverlayTrigger
                        placement="top"
                        delay={{show: 250, hide: toolTipHideDelay}}
                        overlay={renderTooltip}
                        popperConfig={{
                            msg:
                                row.component !== ""
                                    // ? row.component + "_" + tlmName
                                    ? row.tlm_id
                                    : tlmName,
                        }}
                    >
                        <td>
                            {truncate(tlmName, true)}
                        </td>
                    </OverlayTrigger>
                    {renderTelemValues(row)}
                    <OverlayTrigger
                        placement="top"
                        delay={{show: 250, hide: toolTipHideDelay}}
                        overlay={renderTooltip}
                        popperConfig={{msg: toUtc(row?.observed_at, true)?.toString() ?? ""}}
                    >
                        <td>{calcTimeUntil(
                            currentTime,
                            toUtc(row.observed_at),
                        )}</td>
                    </OverlayTrigger>
                </tr>
            );
        });
    };

    return (
        <div ref={ref}>
            {inView && visible? (
                <div
                    className="satmenu fixed inset-0 z-40"
                    style={{display: visible
                        ? "block" : "none", width: "50%", position: "fixed", zIndex: "100", padding: "6px"}}
                >
                    <div>
                        <Tabs
                            defaultActiveKey="satellite"
                            activeKey={currentTab}
                            onSelect={(key) => setCurrentTab(key)}
                            id="satmenu-tabs"
                            fill
                            justify
                        >
                            <Tab eventKey="satellite" title="Telemetry">
                                <div className="pt-3" style={{display: "flex", justifyContent: "space-between",
                                    alignItems: "center"}}>
                                    <h3>
                                        {tle?.tle_line_0} ({tle?.norad_cat_id})
                                    </h3>

                                    <Form.Group

                                        onChange={(e) => {
                                            setTelemCatIndex(e.target.selectedIndex);
                                        }}
                                    >
                                        <Form.Select
                                            className="telemCat"
                                            defaultValue={
                                                telemCats && telemCats[telemCatIndex]
                                            }
                                            value={
                                                telemCats && telemCats[telemCatIndex]
                                            }
                                            readOnly
                                        >
                                            {telemCats
                                    && telemCats.map((row) => {
                                        return <option key={row}>{row}</option>;
                                    })}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                {relSatTelem && (
                                    <p className="pt-1 pb-1">
                                        <b>Most Recent:</b> {mostRecent(relSatTelem)}
                                    </p>
                                )}
                                <Table
                                    striped
                                    bordered
                                    hover
                                    size="sm"
                                    variant="dark"
                                    key={tableColumns}
                                >
                                    {telemCatIndex && telemCats[telemCatIndex] === "FDIR SUMMARY" ? (
                                        <thead>
                                            <tr>
                                                <th>System</th>
                                                {/* <th style={{color: STATUS_COLOR_NOT_LOADED}}>NMoI</th>
                                                <th style={{color: STATUS_COLOR_GREEN}}>OK</th> */}
                                                <th style={{color: STATUS_COLOR_YELLOW}}>YELLOW</th>
                                                <th style={{color: STATUS_COLOR_RED}}>RED</th>
                                                <th>Age</th>
                                            </tr>
                                        </thead>
                                    ) : telemCatIndex && telemCats[telemCatIndex] === "OPEN TICKETS" ? (
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>State</th>
                                                <th>Priority</th>
                                                <th>Age</th>
                                            </tr>
                                        </thead>
                                    ) : (tableColumns === 4 ? (
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>SoC-A</th>
                                                <th>SoC-B</th>
                                                <th>Age</th>
                                            </tr>
                                        </thead>
                                    ) : (
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Value</th>
                                                <th>Age</th>
                                            </tr>
                                        </thead>
                                    ))}
                                    <tbody style={{overflowY: "scroll"}}>
                                        {relSatTelem ? (
                                            renderTelemTableBody(relSatTelem)
                                        ) : (
                                            <tr>
                                                <td colSpan="100%">
                                                    {/* loading spinner */}
                                                    <div style={{display: "flex",
                                                        justifyContent: "center", alignItems: "center", height: "100px"}}>
                                                        <svg
                                                            version="1.1"
                                                            id="L9"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            x="0px"
                                                            y="0px"
                                                            viewBox="0 0 100 100"
                                                            enableBackground="new 0 0 0 0"
                                                            xmlSpace="preserve"
                                                            style={{width: "50px", height: "50px"}}
                                                        >
                                                            <path
                                                                fill="#fff"
                                                                // eslint-disable-next-line max-len
                                                                d="M73.2,50c0-12.8-10.4-23.2-23.2-23.2S26.8,37.2,26.8,50H10c0-22.1,17.9-40,40-40s40,17.9,40,40H73.2z"
                                                            >
                                                                <animateTransform
                                                                    attributeName="transform"
                                                                    attributeType="XML"
                                                                    type="rotate"
                                                                    dur="1s"
                                                                    from="0 50 50"
                                                                    to="360 50 50"
                                                                    repeatCount="indefinite"
                                                                />
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Tab>

                            <Tab eventKey="settings" title="Settings" className="p-3">
                                <p>
                            *Your settings will be saved and automatically
                            loaded next time.
                                </p>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formCADModel"
                                    onChange={(e) => {
                                        localStorage.setItem(
                                            "cadModelIndex",
                                            e.target.value,
                                        );
                                        setCadModelIndex(parseInt(e.target.value));
                                    }}
                                >
                                    <Form.Label>CAD Model</Form.Label>
                                    <Form.Select
                                        className="width-50"
                                        defaultValue={cadModelIndex}
                                    >
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formOrbitLine"
                                    onChange={(e) => {
                                        localStorage.setItem(
                                            "showFuture",
                                            e.target.value,
                                        );
                                        setShowFuture(e.target.value);
                                    }}
                                >
                                    <Form.Label>Orbit Line</Form.Label>
                                    <Form.Select defaultValue={showFuture}>
                                        <option>Future</option>
                                        <option>Past</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formShowAxis"
                                    onChange={(e) => {
                                        const newVal
                                    = e.target.value && e.target.value === "true";
                                        localStorage.setItem("showAxis", newVal);
                                        setShowAxis(newVal);
                                    }}
                                >
                                    <Form.Label>Show Axis</Form.Label>
                                    <Form.Select defaultValue={showAxis}>
                                        <option>true</option>
                                        <option>false</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formShowSAA"
                                    onChange={(e) => {
                                        const newVal
                                    = e.target.value && e.target.value === "true";
                                        localStorage.setItem("showSAA", newVal);
                                        setShowSAA(newVal);
                                    }}
                                >
                                    <Form.Label>Show South Atlantic Anomaly</Form.Label>
                                    <Form.Select defaultValue={showSAA}>
                                        <option>true</option>
                                        <option>false</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formTelemType"
                                    onChange={(e) => {
                                        localStorage.setItem(
                                            "TelemType",
                                            e.target.value,
                                        );
                                        setTelemType(e.target.value);
                                    }}
                                >
                                    <Form.Label>Telemetry Type</Form.Label>
                                    <Form.Select defaultValue={telemType}>
                                        <option>{store.user.isAST ? "Automatic" : "Latest"}</option>
                                        <option>YP</option>
                                        <option>YM</option>
                                    </Form.Select>
                                </Form.Group>
                            </Tab>

                            <Tab eventKey="account" title="Account" className="p-3">
                                <h4 className="pt-3">User: {store.user.username}</h4>

                                <Button className="mt-3" onClick={handleLogout}>
                            Logout
                                </Button>
                            </Tab>
                        </Tabs>
                    </div>
                </div>)
                : null}
        </div>
    );
});
SatMenu.displayName = "GroundStationTable";

SatMenu.propTypes = {
    satellite: PropTypes.object,
    visible: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    popper: PropTypes.object,
};

export default SatMenu;
