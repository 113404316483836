import {useMemo, useContext, useEffect, useState, useRef} from "react";
import {AppState} from "lib/context/AppProvider";
import {
    gstime,
    propagate,
    eciToEcf,
    twoline2satrec,
} from "satellite.js";
import * as THREE from "three";
import model1 from "lib/assets/models/53807_1.glb";
import model2 from "lib/assets/models/53807_2.glb";
import model3 from "lib/assets/models/53807_3.glb";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader.js";
import {supportedSat} from "lib/helpers/utilities.js";
import SatelliteWorker from "lib/workers/propagation.worker.mjs";
import {throttle} from "lodash";

let cadMESH;
const models = [model1, model2, model3];

const UPDATE_INTERVAL = 2; // INTEGER increments of the timer interval (5s) => 2 x 5s = 10s

/**
 * Do not refactor this using `navigator.hardwareConcurrency - 1`.
 * This is a 'fill or kill' implementation, not a task queue like CesiumJS's TaskProcessor.
 * Context switching due to thread starvation is acceptable here.
 * The goal is to update satellites as accurately and frequently as possible.
 * Clock updates are reduced to once every five seconds—sufficient for propagation
 * on modern systems. A standard worker pool would cause queuing on systems with fewer
 * cores than satellites, leading to some satellites missing updates and compounding errors.
 * Implementing a FIFO queue adds unnecessary complexity.
 **/


const data = {};

const useRenderSatellites = (
    globeEl,
    showFuture,
    propStepSize,
    globeRadius,
    setHaloData,
    trackSat,
    hiddenSatellites,
    sunPosAt,
    sun,
    showAxis,
    cadModelIndex,
    satellites,
) => {
    const {store} = useContext(AppState);
    const {currentTime, selectedSatIndex} = store;
    const [lastPovDiff, setLastPovDiff] = useState(null);
    const [lastPov, setLastPov] = useState(null);
    const [lastPosArr, setLastPosArr] = useState({
        sats: {},
        type: showFuture,
    });

    const [trackSatDirty, setTrackSatDirty] = useState(false);

    // Re-render satObject if the MESH changes
    const satObject = useMemo(() => {
        if (!cadMESH || !supportedSat(satellites, selectedSatIndex)) return undefined;
        return cadMESH;
    }, [cadMESH]);


    // Function to create a worker for a satellite
    const createWorkerForSatellite = (sat, id, index, _currentTime, _selectedSatIndex) => {
        if (!data[id]?.worker) {
            console.log(id, "Creating Worker");
            data[id] = {
                worker: new SatelliteWorker(),
                processing: false,
                index: index,
                lastProcessed: 0,
            };
        }

        const {worker} = data[id];

        // Set up the worker message handler
        worker.onmessage = (e) => {
            const {newPosArr} = e.data;

            if (data[id] && data[id].processing) {
                // Mark the satellite as no longer processing
                setLastPosArr((prevState) => {
                    const newState = {
                        sats: {
                            ...prevState.sats,
                            [id]: {
                                index: data[id].index,
                                posArr: newPosArr,
                            },
                        },
                        type: showFuture,
                    };
                    return newState;
                });
                data[id].processing = false;
                data[id].lastProcessed = Date.now();

                if (index === _selectedSatIndex) {
                    setTrackSatDirty(true);
                }
            }
        };

        // Check if the worker is already processing
        if (!data[id].processing) {
            data[id].processing = true;
            console.log(id, "Processing Worker");

            const lastSatPosArr = lastPosArr.sats[id]?.posArr ?? []; // TODO: this is using lastPosArr from last render

            // Send data to the worker
            worker.postMessage({
                sat,
                propStepSize,
                showFuture,
                lastSatPosArr,
                lastShowFuture: lastPosArr.type,
                currentTime: new Date(_currentTime),
                id,
            });
        }
    };

    const tProp = (_satellites, _hiddenSatellites, _selectedSatIndex, _currentTime) => {
        // Create workers for each satellite if there isn't already a worker for that satellite
        for (let i = 0; i < _satellites.length; i++) {
            const sat = _satellites[i];
            const id = sat?.sat_id;

            if (data[id]?.worker && data[id]?.processing) continue;
            if (sat.tle && sat.tle.tle_line_1 && sat.tle.tle_line_2) {
                sat.satRec = twoline2satrec(sat.tle.tle_line_1, sat.tle.tle_line_2);
            }
            if (sat.satRec
                && supportedSat(_satellites, i)
                && (!_hiddenSatellites.includes(i) || i === _selectedSatIndex)
            ) {
                createWorkerForSatellite(sat, id, i, _currentTime, _selectedSatIndex);
            }
        }
    };

    const tPropThrottled = useRef(
        throttle(tProp, UPDATE_INTERVAL),
    ).current;

    const intervalsUntilProp = useRef(UPDATE_INTERVAL);

    const prevDeps = useRef({selectedSatIndex, showFuture, propStepSize, hiddenSatellites, satellites});

    useEffect(() => {
        const {
            selectedSatIndex: prevSelectedSatIndex,
            showFuture: prevShowFuture,
            propStepSize: prevPropStepSize,
            hiddenSatellites: prevHiddenSatellites,
            satellites: prevSatellites,
        } = prevDeps.current;

        // Check if any dependency other than currentTime has changed
        const hasOtherDepsChanged
            = selectedSatIndex !== prevSelectedSatIndex
            || showFuture !== prevShowFuture
            || propStepSize !== prevPropStepSize
            || hiddenSatellites !== prevHiddenSatellites
            || satellites !== prevSatellites;

        // Determine whether all satellites have already been propagated
        const allVisibleSatsRecentlyPropagated = satellites.every((sat, index) => {
            const visible = !hiddenSatellites.includes(index) || index === selectedSatIndex;
            if (!visible) {
                return true;
            }

            const id = sat?.sat_id;
            const recentlyPropagated = data[id]?.worker && data[id]?.lastProcessed >= Date.now() - UPDATE_INTERVAL;
            return recentlyPropagated;
        });

        if (satellites.length) {
            if (hasOtherDepsChanged) {
                if (allVisibleSatsRecentlyPropagated) { //  no need to prop more than necessary
                    tPropThrottled(satellites, hiddenSatellites, selectedSatIndex, currentTime);
                } else { // at least one is out of date - should force prop
                    tProp(satellites, hiddenSatellites, selectedSatIndex, currentTime);
                }
            } else { // interval propagation
                intervalsUntilProp.current -= 1;
                if (intervalsUntilProp.current === 0) {
                    tPropThrottled(satellites, hiddenSatellites, selectedSatIndex, currentTime);
                    intervalsUntilProp.current = UPDATE_INTERVAL;
                }
            }
        }

        // Update the previous dependencies
        prevDeps.current = {
            selectedSatIndex,
            showFuture,
            propStepSize,
            hiddenSatellites,
            satellites,
        };
    }, [selectedSatIndex, currentTime, showFuture, propStepSize, hiddenSatellites, satellites]);


    const satData = useMemo(() => {
        const satIds = Object.keys(lastPosArr.sats).filter((id) => {
            const index = lastPosArr.sats[id].index;
            return !hiddenSatellites.includes(index) || index === selectedSatIndex;
        }); // take only unhidden satellites
        if (satIds.length === 0) return [];

        return satIds.map((id) => {
            const satD = lastPosArr.sats[id];
            const index = satD.index;
            const posArr = satD.posArr;

            return {
                index: index,
                pos: {
                    lat: posArr[0][0],
                    lng: posArr[0][1],
                    alt: posArr[0][2],
                    // name: tles.tle_line_0.split("0 ")[1],
                },
                path: posArr,
            };
        });
    }, [lastPosArr?.sats, currentTime, hiddenSatellites]);

    const getSelectedSatData = () => {
        return satData?.find((s) => s.index === selectedSatIndex);
    };

    //  Center on sat when changing selection
    useEffect(() => {
        const satPos = getSelectedSatData()?.pos;
        if (!globeRadius || !satPos) return undefined;
        globeEl.current.pointOfView({
            lat: satPos.lat,
            lng: satPos.lng,
        });
    }, [selectedSatIndex]);

    const smallScale = useRef(false);

    // Attitude change
    useEffect(() => {
        // TODO: adjust attitude for all satellites in constellation
        if (!cadMESH || !supportedSat(satellites, selectedSatIndex)) return;

        if (globeEl.current.pointOfView().altitude <= 0.3) {
            if (!smallScale.current) {
                const s = 0.0001;
                cadMESH.scale.set(s, s, s);
                smallScale.current = true;
            }
        } else {
            if (smallScale.current) {
                cadMESH.scale.set(0.001, 0.001, 0.001);
                smallScale.current = false;
            }
        }

        const sat = satellites[selectedSatIndex];

        if (!(sat?.satRec)) return;

        // TODO: reuse worker prop instead of propagating again?
        const prop = propagate(sat?.satRec, currentTime);
        const gmst = gstime(currentTime);
        const posVector = eciToEcf(prop.position, gmst);
        const velVector = eciToEcf(prop.velocity, gmst);

        cadMESH.lookAt(
            new THREE.Vector3(velVector.y, velVector.z, velVector.x),
        );
        cadMESH.up = new THREE.Vector3(posVector.y, posVector.z, posVector.x);
        cadMESH.rotateX(Math.PI);
        cadMESH.rotateY(Math.PI / 2);

        if (sat?.attitude) {
            cadMESH.rotateX((sat?.attitude.roll * Math.PI) / 180); // roll
            cadMESH.rotateZ((sat?.attitude.pitch * Math.PI) / 180); // pitch
            cadMESH.rotateY((sat?.attitude.yaw * Math.PI) / 180); // yaw
            // TODO: all the satellites are rotating the same amount!
        }
    }, [currentTime]);

    // Update MESH if CAD Option changed
    useEffect(() => {
        // Cad Models are selected as 1,2,3, so index 0,1,2
        const loader = new GLTFLoader();
        loader.load(
            models[cadModelIndex - 1],
            (gltf) => {
                if (gltf && gltf.scene) {
                    cadMESH = gltf.scene.children[0];
                    cadMESH.position.set(0, 0, 0);
                    const s = smallScale.current ? 0.0001 : 0.001;
                    cadMESH.scale.set(s, s, s);
                }
            },
            undefined,
            (error) => {
                console.error(error);
            },
        );
    }, [cadModelIndex, selectedSatIndex]);

    // Axis Changed Event
    useEffect(() => {
        if (!cadMESH) return;
        if (showAxis) {
            cadMESH.add(new THREE.AxesHelper(10000));
        } else {
            // remove axis
            cadMESH.children.forEach((child) => {
                if (child.type === "AxesHelper") cadMESH.remove(child);
            });
        }
    }, [cadMESH, showAxis]);

    // ran when trackSat changes and sets initial camera position and target
    useEffect(() => {
        const satPos = getSelectedSatData()?.pos;
        if (!satPos || !globeEl) return undefined; // Wait for globe to load first

        if (trackSat) {
            globeEl.current.pointOfView({
                lat: satPos.lat,
                lng: satPos.lng,
            });
        } else {
            setHaloData([{}]);

            globeEl.current.controls().target.set(0, 0, 0);
            globeEl.current.controls().minDistance = 200; // Decrease this number to zoom in more
            globeEl.current.controls().update();
        }
    }, [trackSat]);

    const updateTrackSat = () => {
        const satPos = getSelectedSatData()?.pos;

        if (trackSat) {
            let pov = globeEl.current.pointOfView();
            if (
                !lastPov
                || Math.abs(lastPov.lat - pov.lat) > 0.0001
                || Math.abs(lastPov.lng - pov.lng) > 0.0001
            ) {
                // camera has moved
                if (!lastPov) {
                    // first render
                    globeEl.current.pointOfView({
                        lat: satPos.lat,
                        lng: satPos.lng,
                    });
                    pov = globeEl.current.pointOfView();
                }
                const povDiff = {
                    lat: pov.lat - satPos.lat,
                    lng: pov.lng - satPos.lng,
                };
                setLastPovDiff(povDiff);
            } else {
                globeEl.current.pointOfView({
                    lat: satPos.lat + lastPovDiff.lat,
                    lng: satPos.lng + lastPovDiff.lng,
                });
            }

            const satPosGlobe = globeEl.current.getCoords(
                satPos.lat,
                satPos.lng,
                satPos.alt,
            );
            globeEl.current.controls().target.set(satPosGlobe.x, satPosGlobe.y, satPosGlobe.z);
            globeEl.current.controls().minDistance = 0;
            globeEl.current.controls().update();

            setHaloData([
                {
                    lat: satPos.lat,
                    lng: satPos.lng,
                    alt: satPos.alt,
                },
            ]);

            setLastPov(globeEl.current.pointOfView());
        }
    };

    useEffect(() => {
        if (trackSatDirty) {
            updateTrackSat();
        }
        setTrackSatDirty(false);
    }, [trackSatDirty]);

    // Update Camera and Sun Positions
    useEffect(() => {
        const satPos = getSelectedSatData()?.pos;

        if (!satPos || !globeEl || !supportedSat(satellites, selectedSatIndex)) {
            setHaloData([{}]);
            return undefined; // Wait for globe to load first
        }

        updateTrackSat();

        // Update the sun position
        const sunPos = sunPosAt();
        const sunCoords = globeEl.current.getCoords(sunPos[0], sunPos[1]);
        sun.position.set(sunCoords.x, sunCoords.y, sunCoords.z);
    }, [currentTime, trackSat]);

    return {satData, satObject};
};

export default useRenderSatellites;
