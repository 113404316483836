import React, {useContext, useEffect, useState} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {calcTimeUntilColon, toUtc} from "lib/helpers/globeview/useUtilities";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import {AppState} from "lib/context/AppProvider";
import {GlobeState} from "lib/context/GlobeProvider";
import {Checkbox} from "@mui/material";
import {grey} from "@mui/material/colors";
import {getCurrentPass, getNextPass} from "./GroundStationTable";
import {aocsStatus, epsStatus, medicStackStatus} from "lib/status";
export const STATUS_COLOR_GREEN = "rgb(159, 205, 99)";
export const STATUS_COLOR_YELLOW = "rgb(255, 254, 85)";
export const STATUS_COLOR_RED = "rgb(214, 47, 31)";
export const STATUS_COLOR_NOT_LOADED = "rgb(130, 130, 130)";
export const STATUS_COLOR_WHITE = "rgb(255, 255, 255)";

export const STATUS_COLORS = [STATUS_COLOR_RED, STATUS_COLOR_YELLOW, STATUS_COLOR_GREEN, STATUS_COLOR_NOT_LOADED];

const TOOLTIP_LARGE_CLASS = "my-tooltip-leftmaxwidth";
const sx = {xs: 12, sm: 12, md: 12, lg: 12, xl: 12};

export const selectSat = (satId, store) => {
    const {satellites, setSelectedSatIndex} = store;
    for (let i = 0; i < satellites.length; i++) {
        if (satellites[i].sat_id === satId) {
            setSelectedSatIndex(i);
            break;
        }
    }
};

const updateSatMenu = (props, globe, newTelemCatIndex=null, newTab="satellite") => {
    const {
        showSatMenu,
        setShowSatMenu,
        selectedSatIndex,
        satellites,
    } = props.store;

    const {
        telemCatIndex,
        setTelemCatIndex,
        currentTab,
        setCurrentTab,
    } = globe;

    const sameSat = satellites[selectedSatIndex].sat_id === props.sat.sat_id;
    const sameTLM = newTelemCatIndex === null || telemCatIndex === newTelemCatIndex;
    const sameTab = newTab === null || currentTab === newTab;

    if (sameSat && sameTLM && sameTab) {
        setShowSatMenu(!showSatMenu);
    } else {
        setShowSatMenu(true);
    }
    if (newTelemCatIndex !== null) {
        setTelemCatIndex(newTelemCatIndex);
    }
    if (newTab !== null) {
        setCurrentTab(newTab);
    }
    selectSat(props.sat.sat_id, props.store);
};

const SAT_ID_SHORTHANDS = {
    "BLUEWALKER-3": "BW3",
    "SPACEMOBILE-001": "SM001",
    "SPACEMOBILE-002": "SM002",
    "SPACEMOBILE-003": "SM003",
    "SPACEMOBILE-004": "SM004",
    "SPACEMOBILE-005": "SM005",
};

const renderTooltip = (props) => {
    let message = "";
    let toolclass = "my-tooltip";

    if (props.popper.state) {
        message = props.popper.state.options.msg;
        toolclass = props.popper.state.options.toolclass;
    }

    return (
        <Tooltip style={{color: "red"}} className={toolclass} {...props}>
            <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: message}} />
        </Tooltip>
    );
};

const SatCardId = (props) => {
    const theme = useTheme();

    let displayId = props.sat.sat_id;
    if (props.sat.sat_id.toUpperCase() in SAT_ID_SHORTHANDS) {
        displayId = SAT_ID_SHORTHANDS[props.sat.sat_id.toUpperCase()];
    }

    let tooltip = props.sat?.description ?? displayId;
    const ind = tooltip.indexOf("(");
    if (tooltip.includes("PES")) {
        if (ind > 0) {
            tooltip = tooltip.slice(0, ind-1) + "\n" + tooltip.slice(ind);
        }
    }

    return (
        <OverlayTrigger
            placement="top"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
            popperConfig={{msg: tooltip}}
        >
            <div style={{
                textOverflow: "ellipsis",
                // whiteSpace: "nowrap",
                overflow: "hidden",
                color: theme.palette.text.secondary,
                alignItems: "center",
                justifyContent: "left",
                fontSize: "28cqw",
                marginLeft: "6cqw",
                marginTop: "0px",
            }}>{displayId}</div>
        </OverlayTrigger>
    );
};

const SatCardTickets = (props) => {
    // const theme = useTheme();
    const {globe} = useContext(GlobeState);

    const handleClick = () => {
        updateSatMenu(props, globe, 7);
    };

    const fontSize = props.sat.open_tickets >= 100 ? "13" : "18";

    // ticket icon is 263px wide
    // interior area is 180px
    // interior deflection from left is 26px
    // 180 / 263 * 45cqw = 30.798cqw
    // 26 / 263 * 45cqw = 4.448cqw

    // centerpoint was 263/2 = 131.5px
    // now is at 26+(180/2) = 116px
    // need to move entire thing 131.5-116 = 15.5px to the right to maintain centerpoint
    // 15.5 / 263 * 45cqw = 2.652cqw
    return (
        <div className="satCardTickets icon-ticket" style={{
            // fontSize: "12px",
            // width: "30px",
            // height: "20px",
            width: "45cqw",
            height: "30cqw",
            marginLeft: "2.652cqw",
            // border: `1px solid ${theme.palette.text.secondary}`,
        }} onClick={handleClick}>
            <div className="satCardTickets" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: `${fontSize}cqw`,
                width: "30.798cqw",
                height: "30cqw",
                marginLeft: "4.448cqw",
            }}>
                {props.sat.open_tickets}
            </div>
        </div>
    );
};

const SatCardStack = (props) => {
    const status = medicStackStatus(props.sat.medic_stack?.location, props.sat.medic_stack?.state);
    const stack = status?.displayValue ?? "-";
    const color = STATUS_COLORS[status.overall];

    const {globe} = useContext(GlobeState);
    const {store} = useContext(AppState);
    const {telemType} = store;

    const tooltip = getStatusTooltips(status,
        ["MEDIC_STACK_LOCATION", "MEDIC_STACK_STATE"]) + "\n\nRequested telemetry stack: " + telemType;

    const handleClick = () => {
        updateSatMenu(props, globe, null, "settings");
    };

    return (
        <OverlayTrigger
            placement="top"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
            popperConfig={{msg: tooltip, toolclass: TOOLTIP_LARGE_CLASS}}
        >
            <div className="satCardStack" style={{
                // fontSize: "16px",
                color: color,
                fontSize: "24cqw",
            }} onClick={handleClick}>{stack}</div>
        </OverlayTrigger>
    );
};

const SatCardFDIR = (props) => {
    const {globe} = useContext(GlobeState);

    const handleClick = () => {
        // open FDIR dashboard
        // window.open("https://grafana.saberastro.com/d/sensmetry-summary-csat-slim/csat-summary-dashboard-slim-packet?orgId=1&refresh=1s");
        updateSatMenu(props, globe, 8);
    };

    const loaded = props.sat.fdir_flags;

    const numRedFlags = loaded ? props.sat.fdir_flags[0] : "-";
    const numYellowFlags = loaded ? props.sat.fdir_flags[1] : "-";

    const fontSize = numRedFlags >= 10 || numYellowFlags >= 10 ? "13" : "18";

    const colorRedFlag
        = loaded
            ? (numRedFlags === 0 && numYellowFlags === 0
                ? STATUS_COLOR_GREEN
                : (numRedFlags === 0
                    ? STATUS_COLOR_WHITE
                    : STATUS_COLOR_RED))
            : STATUS_COLOR_NOT_LOADED;
    const colorYellowFlag
        = loaded
            ? (numRedFlags === 0 && numYellowFlags === 0
                ? STATUS_COLOR_GREEN
                : (numYellowFlags === 0
                    ? STATUS_COLOR_WHITE
                    : STATUS_COLOR_YELLOW))
            : STATUS_COLOR_NOT_LOADED;

    return (
        <div className="satCardFDIR" style={{
            // fontSize: "12px",
            // width: "30px",
            // height: "15px",
            fontSize: `${fontSize}cqw`,
            width: "45cqw",
            height: "22.5cqw",
        }} onClick={handleClick}>
            <div className="satCardFDIR" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                height: "100%",
                color: "black",
                backgroundColor: colorRedFlag,
                float: "left",
                border: "1px solid black",
            }}>{numRedFlags}</div>
            <div className="satCardFDIR" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                height: "100%",
                color: "black",
                backgroundColor: colorYellowFlag,
                float: "right",
                border: "1px solid black",
            }}>{numYellowFlags}</div>
        </div>
    );
};

const getStatusTooltip = (status, key) => {
    let v;
    if (status.individual[key]) {
        const color = STATUS_COLORS[status.individual[key].status];
        v = `<span style="color: ${color}">${status.individual[key].value}</span>`;
    } else {
        v = "N/A";
    }
    return `${key}: ${v}`;
};

const getStatusTooltips = (status, keys) => {
    return keys.map((key) => getStatusTooltip(status, key)).join("\n");
};

const SatCardAOCS = (props) => {
    const theme = useTheme();
    const {globe} = useContext(GlobeState);

    const handleClick = () => {
        updateSatMenu(props, globe, 2);
    };

    const color = STATUS_COLORS[props.sat.aocs_status.overall];

    const tooltip = getStatusTooltips(props.sat.aocs_status,
        ["SC_MODE", "AOCS_ACMODE_ACTIVE",
            "RWA_YM0_Z_MEASSPEED", "RWA_YM1_X_MEASSPEED", "RWA_YP0_Z_MEASSPEED", "RWA_YP1_X_MEASSPEED"]);

    return (
        <OverlayTrigger
            placement="top"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
            popperConfig={{msg: tooltip, toolclass: TOOLTIP_LARGE_CLASS}}
        >
            <div className="satCardAOCS" style={{
                // fontSize: "16px",
                padding: "5.5cqw",
                fontSize: "24cqw", // px conversion ratio: 1.5
                // maxWidth: "100%",
                // width: "90cqw",
                // height: "37.5cqw",
                color: "black", // "rgb(89, 89, 89)",
                borderRadius: "1px",
                backgroundColor: color,
                border: `1px solid ${theme.palette.text.secondary}`,
                cursor: "pointer",
            }} onClick={handleClick}>AOCS</div>
        </OverlayTrigger>
    );
};

const SatCardBattery = (props) => {
    const loaded = props.sat.battery_level !== "-" && props.sat.battery_level !== "N/A";
    const batteryStatus = epsStatus(props.sat.battery_level);

    const color
        = !loaded
            ? STATUS_COLOR_NOT_LOADED
            : STATUS_COLORS[batteryStatus.overall];

    const tooltip = getStatusTooltips(batteryStatus,
        ["SOC_BATTERY1AND2_TOTAL_PERCENT"]);

    const {globe} = useContext(GlobeState);

    const handleClick = () => {
        updateSatMenu(props, globe, 4);
    };

    // icon is 584px
    // 20px padding inside + 10px margin = 30px overall padding top bottom
    // 30 / 584 * 90cqw = 4.623cqw top bottom

    // 20px padding inside + 30px margin = 50px overall padding left right
    // 50 / 584 * 90cqw = 7.705cqw left right

    const pad = 25;
    const padTB = (pad+8) / 584 * 90;
    const padLR = (pad+30) / 584 * 90;

    return (
        <OverlayTrigger
            placement="top"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
            popperConfig={{msg: tooltip, toolclass: TOOLTIP_LARGE_CLASS}}
        >
            <div>
                <div className="satCardBattery icon-battery" style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "90cqw",
                    height: "37.5cqw",
                    cursor: "pointer",
                    padding: `${padTB}cqw ${padLR}cqw ${padTB}cqw ${padLR}cqw`, // t r b l
                }} onClick={handleClick}>
                    <div className="satCardBattery" style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // fontSize: "12px",
                        fontSize: "19cqw", // "16.5cqw",
                        // width: "60px",
                        // height: "25px",
                        // width: "90cqw",
                        // height: "37.5cqw",
                        width: "100%",
                        height: "100%",
                        borderRadius: "1.5px",
                        color: "black",
                        backgroundColor: color,
                        // border: "1px solid black",
                        cursor: "pointer",
                    }} onClick={handleClick}>{props.sat.battery_level}{loaded ? "%" : ""}</div>
                </div>
            </div>
        </OverlayTrigger>
    );
};

const SatCardTLMLeft = (props) => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "left",
            // fontSize: "10px",
            fontSize: "15cqw",
            height: "100%",
        }}>Last TLM<br />Next TLM</div>
    );
};

const timeStr = (timeA, timeB) => {
    return (timeB && timeA) ? calcTimeUntilColon(timeB, timeA) : "-";
};

const SatCardTLMRight = (props) => {
    const theme = useTheme();
    const {store} = useContext(AppState);
    const {currentTime, satellites} = store;
    const {globe} = useContext(GlobeState);

    const {
        setShowConstellationGrid,
        setShowOverpassTable,
    } = globe;

    const handleClick = () => {
        selectSat(props.sat.sat_id, props.store);
        setShowConstellationGrid(false);
        setShowOverpassTable(true);
    };

    const [tlmStrings, setTLMStrings]
        = useState([
            "-", "-", null, false,
        ]);

    useEffect(() => {
        const sat = satellites.find((s) => s.sat_id === props.sat.sat_id);

        const nextPass = getNextPass(sat, currentTime);
        const nextTLM = nextPass?.aos;

        const currentPass = getCurrentPass(sat, currentTime);
        const nextTLMDesc = (currentPass ? `<b>In pass:</b>\n${currentPass.gs_name}\n` : "")
             + (nextPass ? "<b>Next pass:</b>\n" + nextPass?.gs_name : "");

        setTLMStrings([
            timeStr(props.sat.last_tlm ? toUtc(props.sat.last_tlm) : null, currentTime),
            timeStr(currentTime, nextTLM ? toUtc(nextTLM) : null),
            nextTLMDesc,
            currentPass !== null && currentPass !== undefined,
        ]);
    }, [props.sat.last_tlm, currentTime, satellites]);

    return (
        <OverlayTrigger
            placement="top"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
            popperConfig={{
                msg: tlmStrings[2] && tlmStrings[2] !== "" ? tlmStrings[2] : "No upcoming contacts",
                toolclass: TOOLTIP_LARGE_CLASS}}
        >
            <div className="satCardTLM" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: props.sat.next_tlm === "-" ? "center" : "left",
                // fontSize: "10px",
                fontSize: "15cqw",
                height: "100%",
                color: tlmStrings[3] ? STATUS_COLOR_GREEN : theme.palette.text.secondary,
            }} onClick={handleClick}>
                {tlmStrings[0]}{tlmStrings[0] !== "-" ? " ago" : ""}
                <br />
                {tlmStrings[1]}{tlmStrings[1] !== "-" ? " eta" : ""}
            </div>
        </OverlayTrigger>
    );
};

const SatCardSection = (props) => {
    return (<Paper variant="outlined"
        sx={{
            padding: "0px", // (theme) => theme.spacing(1),
            textAlign: "center",
            color: (theme) => theme.palette.text.secondary,
            width: "100%",
            // height: "46px", // (184 - 0*2)/4
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#00000000",
            border: "none",
            containerType: "size",
        }}
    >{props.children}</Paper>);
};

const SatCardCheckbox = (props) => {
    const [checked, setChecked] = useState(true);
    const [disabled, setDisabled] = useState(true);

    const {store} = useContext(AppState);
    const {satellites, selectedSatIndex} = store;

    const {globe} = useContext(GlobeState);
    const {hiddenSatellites, setHiddenSatellites} = globe;

    let index = -1;
    for (let i = 0; i < satellites.length; i++) {
        if (props.sat.sat_id === satellites[i]?.sat_id) {
            index = i;
            break;
        }
    }

    const onCheck = (e) => {
        if (!disabled) {
            setChecked(e.target.checked);

            if (e.target.checked) {
                const ind = hiddenSatellites.indexOf(index);
                if (ind > -1) {
                    hiddenSatellites.splice(ind, 1);
                    setHiddenSatellites([...hiddenSatellites]); // doesn't trigger effects if same list...
                }
            } else {
                if (!hiddenSatellites.includes(index)) {
                    setHiddenSatellites([...hiddenSatellites, index]);
                }
            }
        }
    };

    useEffect(() => {
        const isSelected = index === selectedSatIndex;
        setDisabled(isSelected);

        if (isSelected) {
            setChecked(isSelected);
        } else {
            setChecked(!hiddenSatellites.includes(index));
        }
    }, [selectedSatIndex, hiddenSatellites]);

    return (
        <Checkbox className="satCardCheckbox" checked={checked} color="default"
            sx={{
                "& .MuiSvgIcon-root": {fontSize: "12cqw"},
                "color": disabled ? grey[700] : grey[500],
                "&.Mui-checked": {
                    color: disabled ? grey[700] : grey[500],
                },
            }}
            style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                padding: "0px",
            }}
            onChange={onCheck} />
    );
};

const SatCard = (props) => {
    const {globe} = useContext(GlobeState);

    const handleClick = (e) => {
        // if we're not clicking in ones that already bring up a popup
        if (!e.target.className.includes("satCardBattery")
            && !e.target.className.includes("satCardAOCS")
            && !e.target.className.includes("satCardFDIR")
            && !e.target.className.includes("satCardStack")
            && !e.target.className.includes("satCardCheckbox")
            && !e.target.className.includes("satCardTickets")
            && !e.target.className.includes("satCardTLM")) {
            updateSatMenu(props, globe, 0);
        }
    };

    return (
        <Grid item xs={props.size} display="flex" justifyContent="center"
            alignItems="center">
            <Paper square variant="outlined"
                sx={{
                    textAlign: "center",
                    color: (theme) => theme.palette.text.secondary,
                    // height: 184,
                    width: "100%",
                    maxWidth: 150,
                    aspectRatio: "150 / 184",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    containerType: "size",
                }}
            >
                <SatCardCheckbox sat={props.sat}></SatCardCheckbox>
                <Grid container spacing={0} sx={sx} justifyContent="center" height="100%" padding="0px 8px 0px 8px"
                    onClick={handleClick}>
                    <Grid item xs={6}>
                        <SatCardSection>
                            <SatCardId sat={props.sat} store={props.store} />
                        </SatCardSection>
                    </Grid>
                    <Grid item xs={6}>
                        <SatCardSection>
                            <SatCardTickets sat={props.sat} store={props.store} />
                        </SatCardSection>
                    </Grid>
                    <Grid item xs={6}>
                        <SatCardSection>
                            <SatCardStack sat={props.sat} store={props.store} />
                        </SatCardSection>
                    </Grid>
                    <Grid item xs={6}>
                        <SatCardSection>
                            <SatCardFDIR sat={props.sat} store={props.store} />
                        </SatCardSection>
                    </Grid>
                    <Grid item xs={6}>
                        <SatCardSection>
                            <SatCardAOCS sat={props.sat} store={props.store} />
                        </SatCardSection>
                    </Grid>
                    <Grid item xs={6}>
                        <SatCardSection>
                            <SatCardBattery sat={props.sat} store={props.store} />
                        </SatCardSection>
                    </Grid>
                    <Grid item xs={6}>
                        <SatCardSection>
                            <SatCardTLMLeft sat={props.sat} store={props.store} />
                        </SatCardSection>
                    </Grid>
                    <Grid item xs={6}>
                        <SatCardSection>
                            <SatCardTLMRight sat={props.sat} store={props.store} />
                        </SatCardSection>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

const getBattery = (sat) => {
    const bat = sat.telemetry?.find((tlm) => tlm.tlm_name === "SOC_BATTERY1AND2_TOTAL_PERCENT")?.value;
    if (bat === null || typeof bat === "undefined") {
        return null;
    }
    return Math.round(bat * 10) / 10;
};

const getMostRecentTLM = (sat) => {
    const sorted = sat.telemetry?.sort(function(a, b) {
        return new Date(b.observed_at) - new Date(a.observed_at); // sort for most recent first
    });
    return sorted?.length > 0 ? sorted[0] : null;
};

const getComponent = (sat) => {
    const component = getMostRecentTLM(sat)?.component;
    return component ? component.substr(component.indexOf("_")+1) : null;
};

const getFDIR = (sat) => {
    if (!sat.fdir || !("TOTAL" in sat.fdir)) {
        return null;
    }

    return [sat.fdir["TOTAL"]["RED"], sat.fdir["TOTAL"]["YELLOW"]]; // red, yellow
};

const getMedicStack = (sat) => {
    const location = sat.telemetry?.find((tlm) => tlm.tlm_name === "MEDIC_STACK_LOCATION")?.value;
    const state = sat.telemetry?.find((tlm) => tlm.tlm_name === "MEDIC_STACK_STATE")?.value;

    return {
        location: (location === null || typeof location === "undefined") ? null : location,
        state: (state === null || typeof state === "undefined") ? null : state,
    };
};

const ConstellationGrid = () => {
    const hasLoaded = true;

    const {store} = useContext(AppState);
    const {satellites, isConstellationDirty, setConstellationDirty} = store;

    const size = 4;

    const sats = [];
    for (let i=0; i<satellites.length; i++) {
        sats.push({
            sat_id: satellites[i].sat_id,
            i: i,
            display_id: satellites[i].sat_id === "BW3" ? satellites[i].sat_id : i,
        });
    }


    const [displaySats, setDisplaySats] = useState([]);

    useEffect(() => {
        if (isConstellationDirty) {
            setConstellationDirty(false);
        }

        setDisplaySats(sats.map((satMockup) => {
            const sat = satellites.find((sat) => sat.sat_id === satMockup.sat_id);
            if (sat) {
                const bat = getBattery(sat);
                const stack = getComponent(sat);

                const lastTLM = getMostRecentTLM(sat)?.observed_at;
                // const nextTLM = getNextPass(sat, currentTime, toUtc)?.aos;

                const fdirFlags = getFDIR(sat);

                return {
                    sat_id: sat.sat_id,
                    description: sat.description ? `PES ${sat.description}` : sat.description,
                    display_id: satMockup.display_id,
                    open_tickets: sat.open_tickets?.length ?? "-",
                    stack: stack ?? "",
                    fdir_flags: fdirFlags ?? null,
                    aocs_status: aocsStatus(sat.telemetry), // TODO: is this reactive enough?
                    medic_stack: getMedicStack(sat),
                    battery_level: bat === null ? "-" : bat,
                    last_tlm: lastTLM,
                    // next_tlm: nextTLM,
                };
            } else {
                return null;
            }
        }).filter((sat) => sat !== null));
    }, [JSON.stringify(satellites), isConstellationDirty]);

    return hasLoaded ? (
        <Grid container spacing={{xs: 2, sm: 2, md: 4, lg: 8, xl: 8}} sx={sx}
            justifyContent="center" maxWidth="900px" zIndex="500">
            {displaySats.map((sat) => (
                <SatCard key={sat.display_id} size={size} sat={sat} store={store} />
            ))}
        </Grid>
    ) : (
        <span></span>
    );
};

// for es-lint prop validation
SatCard.propTypes = {
    sat: PropTypes.object,
    size: PropTypes.number,
    store: PropTypes.object,
};
SatCardSection.propTypes = {
    sat: PropTypes.object,
    children: PropTypes.object,
    store: PropTypes.object,
};
SatCardId.propTypes = {sat: PropTypes.object, store: PropTypes.object};
SatCardTickets.propTypes = {sat: PropTypes.object, store: PropTypes.object};
SatCardStack.propTypes = {sat: PropTypes.object, store: PropTypes.object};
SatCardFDIR.propTypes = {sat: PropTypes.object, store: PropTypes.object};
SatCardAOCS.propTypes = {sat: PropTypes.object, store: PropTypes.object};
SatCardBattery.propTypes = {sat: PropTypes.object, store: PropTypes.object};
SatCardTLMLeft.propTypes = {sat: PropTypes.object, store: PropTypes.object};
SatCardTLMRight.propTypes = {sat: PropTypes.object, store: PropTypes.object};
SatCardCheckbox.propTypes = {sat: PropTypes.object};

export default ConstellationGrid;
