import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import toastConfig from "lib/config/toast";
import AppProvider from "lib/context/AppProvider";
import App from "pages/_app";
import NotFound from "pages/NotFound";
import {ASTContextProvider} from "./lib/helpers/ASTAuthProvider";
import "lib/scss/app.scss";

import {Amplify} from "aws-amplify";
import {awsConfig} from "lib/config/cognito";
import {QueryClient, QueryClientProvider} from "react-query";

Amplify.configure(awsConfig);

console.clear();
console.log("SABER API URL: " + process.env.REACT_APP_API_BASE_URL);
console.log("AST TELEMETRY API URL: " + process.env.REACT_APP_AST_TELEMETRY_API_BASE_URL);
console.log("SABER HUB (QUINDAR) API URL: " + process.env.REACT_APP_AST_SABER_HUB_BASE_URL);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1800000,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            notifyOnChangeProps: ["data", "error"],
        },
    },
});

const HomePage = (props) => {
    return (
        <AppProvider>
            <Toaster {...toastConfig} />
            <QueryClientProvider client={queryClient}>
                <ASTContextProvider>
                    <App />
                </ASTContextProvider>
            </QueryClientProvider>
        </AppProvider>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter fallback={<NotFound />} exceptionElement={<NotFound />}>
        <Routes>
            <Route path="/satview/*" element={<HomePage />} />
            <Route path="/*" element={<Navigate to="/satview" />} />
        </Routes>
    </BrowserRouter>,
);
