import {useContext, useEffect, useState} from "react";
import {AppState} from "lib/context/AppProvider";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import {getCurrentPassesAll, getNextPassAll} from "./GroundStationTable";
import {selectSat} from "./ConstellationGrid";
import {GlobeState} from "lib/context/GlobeProvider";

const Alerts = (props) => {
    const {store} = useContext(AppState);
    const {satellites, selectedSatIndex, currentTime, user} = store;
    const {height} = props;
    const isMobile = useMediaQuery({query: "(max-width: 1045px)"});
    const style = {
        bottom: 0,
        right: 0,
        height: height,
        minHeight: height,
        width: "fit-content",
        margin: "1rem",
        padding: "0.5rem 1rem",
    };

    const [currentPasses, setCurrentPasses] = useState(null);
    const [nextPass, setNextPass] = useState(null);

    useEffect(() => {
        if (!satellites || satellites.length === 0) {
            setCurrentPasses(null);
            setNextPass(null);
            return;
        }

        setNextPass(getNextPassAll(satellites, currentTime));
        setCurrentPasses(getCurrentPassesAll(satellites, currentTime));
    }, [currentTime, selectedSatIndex, satellites]);

    const {globe} = useContext(GlobeState);
    const {
        setShowConstellationGrid,
        setShowOverpassTable,
        setShowAllContacts,
    } = globe;

    const handleClickNext = () => {
        if (!nextPass) {
            return;
        }
        selectSat(nextPass.sat_id, store);
        setShowConstellationGrid(false);
        setShowOverpassTable(true);
    };

    const handleClickCurrent = (satId) => {
        if (!satId) {
            return;
        }

        if (satId < 0) {
            setShowAllContacts(true);
        } else {
            selectSat(satId, store);
        }

        setShowConstellationGrid(false);
        setShowOverpassTable(true);
    };

    return (
        user.isLoggedIn
        && <div className="alerts p-1" style={style}>
            {isMobile && (
                <Row className="place-center">
                    <Col className="place-center">
                        <span className="clock">
                            {currentTime.toISOString().substring(0, 19) + "Z"}
                        </span>
                    </Col>
                </Row>
            )}

            {currentPasses && currentPasses.length > 0 && (
                (isMobile ? [currentPasses[0]] : currentPasses).map((currentPass) => (
                    <Row key={"currentPass"+currentPass.sat_id} className="place-center">
                        <Col className="place-center" onClick={() => handleClickCurrent(currentPass.sat_id)}
                            style={{cursor: "pointer", textAlign: "left"}}>
                            <b>In Pass ({currentPass.sat_id}): </b>
                            {isMobile ? (
                                <span><br />&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            ) : (<span>&nbsp;</span>)}
                            {currentPass
                                && currentPass.gs_name
                                    + (currentPass.band !== "N/A"
                                        ? " (" + currentPass.band + ")"
                                        : "")}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;For {currentPass.timeUntilLos}
                        </Col>
                    </Row>
                ))
            )}
            {currentPasses && isMobile && currentPasses.length > 1 && (
                <Row className="place-center">
                    <Col className="place-center" onClick={() => handleClickCurrent(-1)}
                        style={{cursor: "pointer", textAlign: "left"}}>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;{`+ ${currentPasses.length-1} other${currentPasses.length-1 === 1 ? "" : "s"}`}</b>
                    </Col>
                </Row>
            )}
            {nextPass && nextPass.gs_name !== undefined && (
                <Row>
                    <Col className="place-center" onClick={handleClickNext} style={{cursor: "pointer", textAlign: "left"}}>
                        <b>Next Pass ({nextPass.sat_id}):</b>
                        {isMobile ? (
                            <span><br />&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        ) : (<span>&nbsp;</span>)}
                        {nextPass.gs_name}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;In {nextPass.timeUntil}
                    </Col>
                </Row>
            )}
        </div>
    );
};

Alerts.propTypes = {
    height: PropTypes.number,
};

export default Alerts;
