import React, {Suspense} from "react";
import Loader from "components/Loader";
import PropTypes from "prop-types";

const Load = ({page}) => {
    return (
        <Suspense fallback={<Loader/>}>
            { page }
        </Suspense>
    );
};

Load.propTypes = {
    page: PropTypes.node.isRequired,
};

export default Load;
