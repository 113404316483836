import React from "react";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";

const AppContainer = ({children}) => {
    return (
        <Container className='appcontainer p-0' fluid>
            {children}
        </Container>
    );
};

AppContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AppContainer;
